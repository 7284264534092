import React, { useEffect, useState } from 'react'
import AccountSideBar from './AccountSideBar'
import TextField from '@mui/material/TextField';
import confirmation from "../../assets/images/tick.jpg"
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {  handleupdateKyc } from '../../services/ApiServices';
import { fetchkyclist } from '../../store/Kyclist';
import { toast } from 'react-toastify';
import Loaders from '../Loader/Loaders';
import '../Account/asset/Kyc.css'


const UploadKycForm = () => {
    const { Kyclist } = useSelector((state) => state.kyclist);
    const [loading, setloading] = useState(false)
    const dispatch = useDispatch()
    const {
        register,
        formState: { errors, isValid, isDirty },
        handleSubmit,
        getValues,
        setValue,
        setFocus,
        reset,
        watch,
        control,
        values,
        trigger,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const [aadhardoc, setaadhardoc] = useState(null);
    const [pandoc, setpandoc] = useState(null);
    const [accountdoc, setaccountdoc] = useState(null);
    const [passportdoc, setpassportdoc] = useState(null);

    useEffect(() => {
        dispatch(fetchkyclist())
    }, [])
   
    const handleAadhar = (event) => {
        const file = event.target.files[0];
        setaadhardoc(file);
        // setValue("aad_doc",file)
    };
    const handlepan = (event) => {
        const file = event.target.files[0];
        setpandoc(file);
        // setValue("aad_doc",file)
    };
    const handleAccount = (event) => {
        const file = event.target.files[0];
        setaccountdoc(file);
        // setValue("aad_doc",file)
    };
    const handlepassport = (event) => {
        const file = event.target.files[0];
        setpassportdoc(file);
        // setValue("aad_doc",file)
    };

    const handleupdate = async (data) => {
        setloading(true)
        const formData = new FormData();

        // Add documents if they exist
        if (accountdoc) formData.append("bank_account_document", accountdoc);
        if (aadhardoc) formData.append("aadar_document", aadhardoc);
        if (pandoc) formData.append("pan_document", pandoc);
        if (passportdoc) formData.append("passport_document", passportdoc);
        // Add other fields
        formData.append("account_holder_name", data?.acc_name ?? '');
        formData.append("account_number", data?.acc_number ?? '');
        formData.append("bank_name", data?.acc_bank ?? '');
        formData.append("branch", data?.acc_branch ?? '');
        formData.append("ifsc_code", data?.acc_ifsc ?? '');
        formData.append("aadar_number", data?.aad_number ? Number(data?.aad_number) : '');
        formData.append("pan_number", data?.pan_number ?? '');
        formData.append("passport_number", data?.pass_number ?? '');
        try {
            const response = await handleupdateKyc(formData);
            if (response.Status == 1) {
                toast.success(response.Message)
                setloading(false)
            } else {
                toast.warning(response.Message)
                setloading(false)
            }

        } catch (error) {

        }

    }
    return (
        <div className="row">
            <Loaders loading={loading} />
            <AccountSideBar page="kyc" />
            <div className="col-12 col-lg-9">
                {Kyclist && (
                    <form onSubmit={handleSubmit(handleupdate)}>
                        <div className="my-account padding-change">
                            <h2 style={{ display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "15px" }}>Aadhaar Details<img style={{ width: "25px", height: "auto", marginLeft: "15px" }} src={confirmation}></img></h2>
                            <div className="row textfield-row-main">
                                <div className="col-12 col-lg-6">
                                    <TextField
                                        id="standard-basic"
                                        label="Aadhaar Number"
                                        variant="standard"
                                        {...register("aad_number")}
                                        defaultValue={Kyclist?.aadar_number}
                                    />
                                </div>
                                <div className="col-12 col-lg-6 document">
                                    <label htmlFor="document-upload" className="document-upload-lable">
                                        {(aadhardoc || Kyclist?.aadar_document)?
                                         <TextField
                                            id="standard-basic"
                                            label=" "
                                            variant="standard"
                                            {...register("aad_doc")}
                                            value={aadhardoc ? aadhardoc.name : Kyclist?.aadar_document}
                                            InputProps={{ readOnly: true }}
                                        />:<></>
                                    }
                                       
                                    </label>
                                    <label className='doclabel'>Upload Aadhaar
                                        <input
                                            accept="image/*,application/pdf"
                                            id="document-upload"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleAadhar}
                                        />
                                    </label>

                                </div>
                            </div>

                            <h2 style={{ display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "15px" }}>Pan Details<img style={{ width: "25px", height: "auto", marginLeft: "15px" }} src={confirmation}></img></h2>
                            <div className="row textfield-row-main">
                                <div className="col-12 col-lg-6">
                                    <TextField
                                        id="standard-basic"
                                        label="Pan Number"
                                        variant="standard"
                                        {...register("pan_number")}
                                        defaultValue={Kyclist?.pan_number}
                                    />
                                </div>
                                <div className="col-12 col-lg-6 document">
                                    <label htmlFor="document-upload" className="document-upload-lable">
                                   { (pandoc || Kyclist?.pan_document) ?
                                        <TextField
                                            id="standard-basic"
                                            label=" "
                                            variant="standard"
                                            {...register("pan_doc")}
                                            value={pandoc ? pandoc.name : Kyclist?.pan_document}
                                            InputProps={{ readOnly: true }}
                                        />:<></>
                                   }
                                    </label>
                                    <label className='doclabel'>
                                        Upload Pan
                                        <input
                                            accept="image/*,application/pdf"
                                            id="document-upload"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handlepan}
                                        />
                                    </label>

                                </div>
                            </div>

                            <h2 style={{ display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "15px" }}>Bank Details<img style={{ width: "25px", height: "auto", marginLeft: "15px" }} src={confirmation}></img></h2>
                            <div className="row textfield-row-main">
                                <div className="col-12 col-lg-6">
                                    <TextField
                                        id="standard-basic"
                                        label="Account Holder Name"
                                        variant="standard"
                                        {...register("acc_name")}
                                        defaultValue={Kyclist?.account_holder_name}
                                    />
                                </div>
                                <div className="col-12 col-lg-6 document">
                                    <TextField
                                        id="standard-basic"
                                        label="Account Number"
                                        variant="standard"
                                        {...register("acc_number")}
                                        defaultValue={Kyclist?.account_number}
                                    />
                                </div>
                            </div>

                            <div className="row textfield-row-main">
                                <div className="col-12 col-lg-6">
                                    <TextField
                                        id="standard-basic"
                                        label="Bank Name"
                                        variant="standard"
                                        {...register("acc_bank")}
                                        defaultValue={Kyclist?.bank_name}
                                    />
                                </div>
                                <div className="col-12 col-lg-6 document">
                                    <TextField
                                        id="standard-basic"
                                        label=" "
                                        variant="standard"
                                        {...register("acc_branch")}
                                        defaultValue={Kyclist?.branch}
                                    />
                                </div>
                            </div>

                            <div className="row textfield-row-main">
                                <div className="col-12 col-lg-6">
                                    <TextField
                                        id="standard-basic"
                                        label="IFSC"
                                        variant="standard"
                                        {...register("acc_ifsc")}
                                        defaultValue={Kyclist?.ifsc_code}
                                    />
                                </div>
                                <div className="col-12 col-lg-6 document">
                                    <label htmlFor="document-upload" className="document-upload-lable">
                                        {(accountdoc|| Kyclist?.bank_document)?
                                        <TextField
                                            id="standard-basic"
                                            label=" "
                                            variant="standard"
                                            value={accountdoc ? accountdoc.name : Kyclist?.bank_document}
                                            InputProps={{ readOnly: true }}
                                        />:<></>}
                                        
                                    </label>
                                    <label className='doclabel'>
                                        Upload Passbook
                                        <input
                                            accept="image/*,application/pdf"
                                            id="document-upload"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleAccount}
                                        />
                                    </label>

                                </div>
                            </div>

                            <h2 style={{ display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "15px" }}>Passport Details<img style={{ width: "25px", height: "auto", marginLeft: "15px" }} src={confirmation}></img></h2>
                            <div className="row textfield-row-main">
                                <div className="col-12 col-lg-6">
                                    <TextField
                                        id="standard-basic"
                                        label="Passport Number"
                                        variant="standard"
                                        {...register("pass_number")}
                                        defaultValue={Kyclist?.passport}
                                    />
                                </div>
                                <div className="col-12 col-lg-6 document">
                                    <label htmlFor="document-upload" className="document-upload-lable">
                                        {(passportdoc || Kyclist?.passport_document)?
                                       <TextField
                                            id="standard-basic"
                                            label=" "
                                            variant="standard"
                                            {...register("pass_doc")}
                                            value={passportdoc ? passportdoc.name : Kyclist?.passport_document}
                                            InputProps={{ readOnly: true }}
                                        /> :<></>
                                    }
                                        
                                    </label>
                                    <label className='doclabel'>
                                        Upload Passport
                                        <input
                                            accept="image/*,application/pdf"
                                            id="document-upload"
                                            type="file"
                                            onChange={handlepassport}
                                            style={{ display: 'none' }}
                                        />
                                    </label>

                                </div>
                            </div>
                            <button className="save" type='submit'>Update</button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}

export default UploadKycForm