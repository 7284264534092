import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthTitle from './AuthTitle';
import RedirectSection from './RedirectSection';
import pswd from "../../assets/images/pswd.png";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterUser } from '../../services/ApiServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loaders from '../Loader/Loaders';

const Signup = () => {
    const navigate = useNavigate()
    const [type, setType] = useState('password');
    const [type1, setType1] = useState('password');
    const [btnText, setBtnText] = useState('fa-eye-slash');
    const [btnText1, setBtnText1] = useState('fa-eye-slash');
    const [btnDisable,setBtnDisable] = useState(true)
    const [loading,setloading] = useState(false)
    const [onld, setOnld] = useState('hideOnlod');
    const authmessage = {
        title1: "Already have an account?",
        Para: "Sign in and discover more",
        Link: '/sign-in',
        button: 'SIGN IN'
    }
    const usernameRegex = /^(?:\d{10}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const passwordRegex = /^(?=.*[^a-zA-Z0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        email: yup.string().required("Email is required"),
        username: yup.string().required("Mobile number required"),
        password: yup
            .string()
            .required("Password required")
            .min(8, "Passwords must be 8 characters or more")
            .max(20, "Max characters limit Reached")
            .matches(
                passwordRegex,
                "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character"
            ),
        ConfirmPassword: yup
            .string()
            .required("Password required")
            .oneOf([yup.ref("password"), null], "Passwords must match"),
    })
    const {
        register,
        handleSubmit,
        reset,
        clearErrors,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data) => {
        if(!btnDisable){
            setloading(true)
            try {
            const response = await RegisterUser({
                name: data?.name,
                email: data?.email,
                username: data?.username,
                password: data?.password,
                confirm: data?.ConfirmPassword
            });
            if (response.Status === 1) {
                toast.success(response.message)
                setTimeout(() => {
                    setloading(false)
                    navigate('/verify-otp', { state: { data: response.Data } });
                }, 1500);
            } else if (response.Status === 0) {
                setloading(false)
           
                setError('username', { type: 'custom', message: response.errors.customer_mobile });
                setError('email', { type: 'custom', message: response.errors.customer_email });
            }
        } catch (error) {
            setloading(false)
            
            // Add logic for handling login failure (e.g., show error message)
        } 
    }
    else{
           
            toast.warn("Please accept terms and conditions to continue")
        }
       
    }
    const showPassword = () => {
        if (type === 'password') {
            setType('text');
            setBtnText('fa-eye');
        } else {
            setType('password');
            setBtnText('fa-eye-slash');
        }
    };
    const showPassword1 = () => {
        if (type1 === 'password') {
            setType1('text');
            setBtnText1('fa-eye');
        } else {
            setType1('password');
            setBtnText1('fa-eye-slash');
        }
    };
    const handleInputChange = (e) => {
        // Allow only numeric input
        const value = e.target.value.replace(/[^0-9]/g, '');
        e.target.value = value;

        // Your additional logic if needed
    };
    const resets = (ev) => {
        if (ev === 'ph') {
            setOnld('');
        }
    };
    return (
        <>
        <Loaders loading={loading}/>
            <div className="signin signup">
                <div className="authform">
                    <div>
                        <AuthTitle title="Register" />
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form">
                                <label>
                                    Name <b style={{ color: 'red' }}>*</b>
                                    <input
                                        name='name'
                                        {...register("name")}
                                    />
                                    <div className="error">{errors.name ? errors.name.message : ''}</div>
                                </label>
                                <label>
                                    Mobile Number <b style={{ color: 'red' }}>*</b>
                                    <div className={`prefix signinPrefix ${onld === 'hideOnlod' ? 'hideOnlod' : ''}`}>
                                        <span>+91</span>
                                        <input
                                            onFocus={() => resets('ph')}
                                            onInput={handleInputChange}
                                            autoComplete="new-mobilenumber"
                                            name='username'
                                            maxLength="10"
                                            {...register("username")}
                                        />
                                    </div>
                                    <div className="error">{errors.username ? errors.username.message : ''}</div>
                                </label>
                                <label>
                                    Email <b style={{ color: 'red' }}>*</b>
                                    <input
                                        name='email'
                                        {...register("email")}
                                    />
                                    <div className="error">{errors.email ? errors.email.message : ''}</div>
                                </label>
                                <label>
                                    Password <b style={{ color: 'red' }}>*</b>
                                    <span style={{ position: 'relative' }} className="eye">
                                        <input
                                            type={type}
                                            autoComplete="new-password"
                                            name='password'
                                            maxLength={20}
                                            {...register("password")}
                                        />
                                        <button className="tg-ps" type='button' onClick={showPassword}>
                                            <FontAwesomeIcon icon={btnText === 'fa-eye' ? faEye : faEyeSlash} />
                                        </button>
                                    </span>
                                    <div className="error">{errors.password ? errors.password.message : ''}</div>
                                </label>
                                <label>
                                    Confirm Password <b style={{ color: 'red' }}>*</b>
                                    <span style={{ position: 'relative' }} className="eye">
                                        <input
                                            type={type1}
                                            autoComplete="new-password"
                                            name='ConfirmPassword'
                                            maxLength={20}
                                            {...register("ConfirmPassword")}
                                        />
                                        <button className="tg-ps" type='button' onClick={showPassword1}>
                                            <FontAwesomeIcon icon={btnText1 === 'fa-eye' ? faEye : faEyeSlash} />
                                        </button>
                                    </span>
                                    <div className="error">{errors.ConfirmPassword ? errors.ConfirmPassword.message : ''}</div>
                                </label>
                                <div className="agreeterms">
                                <input type="checkbox" id="agree" name="vehicle1" onChange={()=>setBtnDisable(!btnDisable)}/>Agree with our Terms & Conditions
                                </div>

                                <button type='submit' >Sign up</button>
                            </div>
                        </form>

                    </div>
                </div>
                <img src={pswd} className="pos-image-auth" />

                <div className="auth-right">
                    <RedirectSection message={authmessage} />
                </div>
            </div>

        </>
    );
};

export default Signup;
