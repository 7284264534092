import React from 'react';
import { Link } from 'react-router-dom';

const SingleProfile = ({ profile }) => {

  return (
    <div className="single_profile top-profile">
      <div className="profile_img">
        <Link
          // to={`/view-profile/${}`}
          className="position-route"
        ></Link>
        <img src={profile.service_provider_image} alt={profile.service_provider_name} />
      </div>
      <div className="profile_det">
        <Link
          // to={}
          className="position-route"
        ></Link>
        <h3>{profile.service_provider_name}</h3>
        <span className="dept">{profile.designation?profile.designation:profile.category}</span>
        <p className="exp_adrsp">{profile.location_name+','+profile.city_name}</p>
        <p className="shrt-des">{profile.organisation}</p>
        <p className="shrt-des">{profile.booking_charge?`Per person ₹${profile.booking_charge}`:''}</p>
      </div>
    </div>
  );
};

export default SingleProfile;
