import React from 'react'
import AppHeader from '../components/Common/Header/AppHeader'
import AppFooter from '../components/Common/AppFooter'
import VerifyOtp from '../components/Account/VerifyOtp'

const MyfamilyOtp = () => {
  return (
    <div className="listing-service-main">
    <AppHeader />
    <div className="mainP HomePage">
        <div className="list_page_layout">
            <div className="listing-main" style={{padding: "40px 0 0"}}>
                <div className="wrapper">
                   <VerifyOtp/>
                </div>
            </div>

        </div>  
        
    </div>      
    <AppFooter/>          
  </div>
  )
}

export default MyfamilyOtp