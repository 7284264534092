import React, { useState } from 'react';
import {  useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import pswd from "../../assets/images/pswd.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AuthTitle from '../../components/Auth/AuthTitle';
import RedirectSection from '../../components/Auth/RedirectSection';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Resendotp, loginUser } from '../../services/ApiServices';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setUserInfo, setusername } from "../../store/userSlice";
import Loaders from '../Loader/Loaders';

const SignIn = () => {
  const navigate = useNavigate()
  const [type, setType] = useState('password');
  const [btnText, setBtnText] = useState('fa-eye-slash');
  const [onld, setOnld] = useState('hideOnlod');
  const [loading,setloading] = useState(false)
  const dispatch = useDispatch();
  const usernameRegex = /^(?:\d{10}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  const passwordRegex = /^(?=.*[^a-zA-Z0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
  const schema = yup.object().shape({
    username: yup.string().required("Mobile number required"),
    password: yup.string().required("Password required").max(20)
 
  })
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    setloading(true)
    try {
      const response = await loginUser({
        username: data?.username,
        password: data?.password,
      });
      if(response.Status===1){
        toast.success(response.Message)
        dispatch(setUserInfo(response))
        
        dispatch(setusername(response.Data.customer_name))
        
        localStorage.setItem('AccessToken', response.access_token);
        setTimeout(() => {
        setloading(false)
        navigate('/');
      }, 1000);
    }else if(response.Status===0){
      setloading(false)
      toast.error(response.Message.customer_mobile?response.Message.customer_mobile[0]:response.Message.customer_email?response.Message.customer_email[0]:response.Message)
    }else if(response.Status===2){
      setloading(false)
      toast.warning(response.Message)
      resendOtp(response.Data)
    }
    
  } catch (error) {
      setloading(false)
      
    }
  }
  const resendOtp = async (Data) => {
    try {
        const response = await Resendotp(Data?.customer_id);
        if (response.Status === 1) {
            toast.success(response.Message)
            setTimeout(() => {
              navigate('/verify-otp', { state: { data: Data } });
          }, 1500);
        } else if (response.Status === 0) {
            toast.error(response.Message)
        }
    } catch (error) {

        // Add logic for handling login failure (e.g., show error message)
    }
};
  const showPassword = () => {
    if (type === 'password') {
      setType('text');
      setBtnText('fa-eye');
    } else {
      setType('password');
      setBtnText('fa-eye-slash');
    }
  };
  const handleInputChange = (e) => {
    // Allow only numeric input
    const value = e.target.value.replace(/[^0-9]/g, '');
    e.target.value = value;

    // Your additional logic if needed
  };
  const resets = (ev) => {
    if (ev === 'ph') {
      setOnld('');
    }
  };
  const checkP=()=>{
    if(this.login.username !=''){
        onld=''
    }
    else {
        onld= 'hideOnlod'
    }
 }
  const authmessage = {
    title1: "New Here?",
    Para: "Sign up and discover more",
    Link: '/sign-up',
    button: 'Sign up'
  }
  const title = 'Login To Your Account'
  return (
    <>
    <Loaders loading={loading}/>
    <div className="signin">
      <div className="authform signin-form-lert">
        <div>
          <AuthTitle title={title} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form">
              <label>
                Mobile Number
                <div className={`prefix signinPrefix ${onld === 'hideOnlod' ? 'hideOnlod' : ''}`}>
                <span>+91</span>
                  <input
                    onFocus={() => resets('ph')}
                    onInput={handleInputChange}
                    autoComplete="new-mobilenumber"
                    name='username'
                    onBlur={checkP}
                    maxLength="10"
                    {...register("username")}
                  />
                </div>
                <div className="error">{errors.username ? errors.username.message : ''}</div>
              </label>
              <label>
                Password
                <span style={{ position: 'relative' }} className="eye">
                  <input
                    type={type}
                    autoComplete="new-password"
                    name='password'
                    maxLength={20}
                    {...register("password")}
                  />
                  <button type='button' className="tg-ps" onClick={showPassword}>
                    <FontAwesomeIcon icon={btnText === 'fa-eye' ? faEye : faEyeSlash} />
                  </button>
                </span>
                <div className="error">{errors.password ? errors.password.message : ''}</div>
              </label>
              <span className="forfgotp">
                <Link to={'/forgot-password'}>Forgot Password?</Link>
              </span>
              <button type='submit'>Sign in</button>
            </div>
          </form>
          <div className="noac mt-2">
            <p>
              Don't have an account? <Link to={'/sign-up'}>Sign up</Link>
            </p>
          </div>
        </div>
      </div>
      <img src={pswd} className="pos-image-auth" />
      <div className="auth-right">
        <RedirectSection message={authmessage} />
      </div>
    </div>
    
    </>
  );
};

export default SignIn;
