import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getNewsPage } from "../services/ApiServices"


const { REACT_APP_SERVER_URL } = process.env;
export const fetchNewsDatas = createAsyncThunk(
  "filters/fetchNewsDatas",
  async (params) => {
    const data = await getNewsPage(1);
        return data.Data;
      }
    );
const NewespageSlice = createSlice({
  name: "NewsSearch",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewsDatas.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNewsDatas.fulfilled, (state, action) => {
        state.top_news=action.payload;
        state.loading = false;
      })
      .addCase(fetchNewsDatas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
 } = NewespageSlice.actions;

export default NewespageSlice.reducer;