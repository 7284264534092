import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import SingleProfile from './SingleProfile';

const RecentlyBooked = ({recent_bookings}) => {
 
  const options = {
    0: { items: 1, nav: false, dots: true },
    480: { items: 1.2, nav: false, dots: true, loop: true, margin: 10 },
    500: { items: 1.5, nav: false, dots: true, loop: true, margin: 10 },
    1000: { items: 3, nav: false, dots: true, loop: true, margin: 10 },
  };

  return (
    <div className="related_profiles">
      <div className="wrapper">
        <h2>Recently Booked</h2>

        <OwlCarousel
          className="comments"
          responsive={options}
          dots={true}
          autoplay
          loop
        >
          {recent_bookings?.map((rel) => (
            <SingleProfile key={rel?.id} profile={rel} />
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default RecentlyBooked;
