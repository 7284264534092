import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { searchserviceProviders } from "../services/ApiServices"


export const fetchSearchResult = createAsyncThunk(
  "searchFilters/meetupFetchSearchResult",
  async (params) => {
    const data = await searchserviceProviders(params);
        return data.Data;
      }
    );

const dataSearchSlice = createSlice({
  name: "Search",
  initialState: {
    loading: false,
    error: "",
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchResult.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSearchResult.fulfilled, (state, action) => {
        state.providerList = action.payload.data;
        state.totalRecords = action.payload.total;
        state.per_page = action.payload.per_page;
        state.loading = false;
      })
      .addCase(fetchSearchResult.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
 } = dataSearchSlice.actions;

export default dataSearchSlice.reducer;