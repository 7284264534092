// apiService.js
import axios from 'axios';


const {REACT_APP_BASE_URL} = process.env; // Replace with your API base URL
const AccessToken = localStorage.getItem('AccessToken');

//Home Page Apis
// home page main data api
export const getHomePageData = async (data) => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/home-new?customer_id=${data?.customerId??''}&cust_latitude=${data?.latitude}&cust_longitude=${data?.longitude}&distance_id=20`,{ requiresSecretkey: true });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};

// home page and news page api
export const getNewsPage = async (num) => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/news-and-events?latest=${num}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//news by id
export const getNewsByid = async (num) => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/news-and-events/${num}/view`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
// home page testimonial data api
export const getTestimonials = async () => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/testimonials`,{ requiresAccessToken: false});
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//Auth section Apis

// login
export const loginUser = async (data) => {
  try {
    const response = await axios.post(`${REACT_APP_BASE_URL}/login?customer_mobile=${data?.username}&password=${data?.password}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//register
export const RegisterUser = async (data) => {
  try {
    const response = await axios.post(`${REACT_APP_BASE_URL}/register?customer_name=${data?.name}&customer_email=${data?.email}&customer_mobile=${data?.username}&password=${data?.password}&password_confirmation=${data?.confirm}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//resend otp
export const Resendotp = async (data) => {
  try {
    const response = await axios.post(`${REACT_APP_BASE_URL}/resend-otp?customer_id=${data}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//verify otp after registration
export const VerifyOTP = async (data) => {
  try {
    const response = await axios.post(`${REACT_APP_BASE_URL}/verify-otp?customer_id=${data?.customer_id}&otp=${data?.otp}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//forgot password to send otp
export const ForgotPasswordApi = async (data) => {
  try {
    const response = await axios.post(`${REACT_APP_BASE_URL}/forgot-password?customer_mobile=${data}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//forgot section password resend otp
export const ResendPaswordOTP = async (data) => {
  try {
    const response = await axios.post(`${REACT_APP_BASE_URL}/resend-password-otp?customer_id=${data}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//forgot section password otp verify
export const VerifyPaswordOTP = async (data) => {
  try {
    const response = await axios.post(`${REACT_APP_BASE_URL}/reset-password-otp-verify?customer_id=${data?.customer_id}&otp=${data?.otp}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//reset password
export const ResetPassword = async (data) => {
  try {
    const response = await axios.post(`${REACT_APP_BASE_URL}/reset-password?customer_id=${data?.customer_id}&password=${data?.password}&password_confirmation=${data?.confirm}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//News letter
export const SubscribeNewsLetter = async (data) => {
  try {
    const response = await axios.post(`${REACT_APP_BASE_URL}/newsletter?email=${data?.email}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//About us
export const AboutusData = async () => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/about-us`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};

//contact info
export const ContactInfo = async () => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/contact-us`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//contact section form submition
export const SubmitContactForm = async (data) => {
  const formData = new FormData();
  formData.append('name', data?.name);
  formData.append('email', data?.email);
  formData.append('subject', data?.subject);
  formData.append('message', data?.Message);
  try {
    const response = await axios.post(`${REACT_APP_BASE_URL}/contact-us/send-mail`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//career info
export const CareerInfo = async () => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/careers`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//career form submit
export const SubmitCareerForm = async (formInput) => {
  const formData = new FormData();
  formData.append('name', formInput.name);
  formData.append('email', formInput.email);
  formData.append('position', formInput.position);
  formData.append('experiance', formInput.experiance);
  formData.append('file', formInput.file);
  formData.append('message', formInput.message);
  try {
    const response = await axios.post(`${REACT_APP_BASE_URL}/career-form`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//privacy and terms api 
export const getTermsandconditionpage = async (data) => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/terms-and-condition`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//Service provider
//service provider categories

export const FilterCategory = async (data) => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/category/list`,{ requiresAccessToken: false });

    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//service provider categories

export const FilterSubCategory = async (data) => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/subcategory/list?category_id=${data ?? 0}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//Service provider speciality   
export const FilterSpecialitty = async (data) => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/specialty/list?category_id=${data?.category_id?data?.category_id:''}&sub_category_id=${data?.subcategory_id?data?.subcategory_id:''}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//Search service providers
export const searchserviceProviders = async (data) => {

  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/service-provider/list-new?category=${data?.selectedCategory ?? ''}&sub_category=${data?.selectedSubCategory ?? ''}&specialty=${data?.selectedSpecialty ?? ''}&cust_latitude=${data?.selectedLatitude ?? 11.2542302}&cust_longitude=${data?.selectedLongitude ?? 75.8368653}&distance_id=${data?.searchDistance?data?.searchDistance: 20}&appointment_date=${data?.appoinment_Date?data?.appoinment_Date:''}&service_provider_name=&sort_by=${data?.sortBy?data?.sortBy:''}&page=${data?.page?data?.page:''}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//Search firm
export const searchFirm = async (data) => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/list-firms-new?category_id=${data?.selectedCategory ?? ''}&cust_latitude=${data?.selectedLatitude ??''}&cust_longitude=${data?.selectedLongitude ?? ''}&distance_id=${data?.searchDistance ?data?.searchDistance: 20}&sort_by=${data?.sortBy?data?.sortBy:''}&name=&page=${data?.page?data?.page:''}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//Available time slots
export const availableSlots = async (data) => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/available-timeslot?service_provider_id=${data?.id}&date=${data?.date}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//Available time slot by id
export const availableSlotByid = async (data) => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/timeslot/${data?.id}/view?date=${data?.date}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
// Service provider view by id
export const viewserviceprovider = async ({id}) => {

  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/service-provider/${id?.id}/view`,{ requiresAccessToken: true });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//Favorite list 
export const getFavoritesList = async ({page}) => {
  try {   
    const response = await axios.get(`${REACT_APP_BASE_URL}/favorites/list?page=${page}`, {
      requiresAccessToken: true
    });
    return response.data;
  } catch (error) {

    
    throw error;
  }
};
//add to Favorite  
export const addFavorite = async ({ id }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/store/favorite?service_provider_id=${id}`,{ requiresAccessToken: true }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//remove from Favorite  
export const removeFavorite = async ({ id }) => {
  try {
    const response = await axios.delete(
      `${REACT_APP_BASE_URL}/delete/favorite?id=${id}`,{ requiresAccessToken: true }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//list firm members  
export const getFirmMemberslist = async ({id,specId,appointment_date,page}) => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/list-firm-members?firm_id=${id}&specialty_id=${specId?specId:''}&page=${page?page:''}&appointment_date=${appointment_date?appointment_date:''}`,{ requiresAccessToken: true }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
//get account details 
export const getAccountDetail = async () => {

  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/my-account`,{ requiresAccessToken: true }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};

// get blood group list 
export const getBloodgrouplist = async () => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/blood-group/list`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
// get country list
export const getcountrylist = async () => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/country/list`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
// get city list
export const getcitylist = async () => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/city/list`,{ requiresAccessToken: false});
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
// get location list
export const getlocationlist = async (id) => {
  try {
    const response = await axios.get(`${REACT_APP_BASE_URL}/location/list?city_id=${id}`,{ requiresAccessToken: false });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};

//update my-account 
export const updateMyAccount = async ({dataxx}) => {
 
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/update/my-account`,
      dataxx, // empty body
      {
        requiresAccessToken: true
      }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//family membar list
export const familymembarList = async ( {page} ) => {
  
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/family-members/list?page=${page}`,{ requiresAccessToken: true }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//List relationship
export const relationList = async () => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/relations/list`,{ requiresAccessToken: true }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//Add family members
export const addFamilyMember = async ({ data }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/family-member/add`,
      {
        name: data.name,
        mobile: data.mobile,
        email: data.email,
        gender: data.gender,
        blood_group: data.blood_group,
        dob: data.dob,
        address: data.address,
        relation: data.relation
      },{ requiresAccessToken: true }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//Update family members
export const updateFamilyMember = async (data) => {
  
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/family-member/update`,
      {
        id:data?.id,
        name: data.name,
        mobile: data.mobile,
        email: data.email,
        gender: data.gender,
        blood_group: data.blood_group,
        dob: data.dob,
        address: data.address,
        relation: data.relation
      },{ requiresAccessToken: true }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//delete family member
export const deletefamilymember = async ({ id }) => {
  try {
    const response = await axios.delete(
      `${REACT_APP_BASE_URL}/family-member/delete?id=${id}`,{ requiresAccessToken: true }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//get family otp
export const getfamilyotp = async ({ num }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/family-member/send-otp?mobile_number=${num}`,{ requiresAccessToken: true }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
// get otp for family member verification
export const verifyfamilyotp = async ({ family_id, otp }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/family-member/verify?family_id=${family_id}&otp=${otp}`,{ requiresAccessToken: true }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
// resend otp for family member verification
export const resendfamilyotp = async ( id ) => {
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/family-member/resend-otp?family_member_id=${id}`,{ requiresAccessToken: true }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//booking list
export const getbookinglist = async ({ page }) => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/bookings/list?page=${page}`,{ requiresAccessToken: true }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};

//Send Family member send otp
export const sendfamilyOtp = async (data) => {
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/family-member/send-otp?mobile_number=${data?.mobile}`,{},{ requiresAccessToken: true });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//verify family member
export const verifyfamilyOtp = async (data) => {
  
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/family-member/verify?family_id=${data?.family_id}&otp=${data?.otp}`,{},{ requiresAccessToken: true });
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//create booking
export const createBooking = async (data) => {
  
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/create/booking`,
      {
        service_provider_id: Number(data?.service_provider_id),
        family_id: data?.family_id,
        date: data?.date,
        time_slot_id: data?.time_slot_id,
      },{ requiresAccessToken: true }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};


// cancel booking
export const cancelBooking = async ({ id }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/booking-cancel`,
      {
        booking_id:id
      }, 
      {
        requiresAccessToken: true
      }
    );
   
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
// refund booking amount
export const refundBookingAmount = async ({ payid,amnt }) => {
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/generate-refund?payment_id=${payid}&amount=${amnt}`,
      {
      }, 
      {
        requiresAccessToken: true
      }
    );
   
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//booking history list
export const getBookingHistoryList = async ({ page }) => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/booking/history?page=${page}`,
      {
        requiresAccessToken: true
      }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//booking view
export const getbookingview = async ({id}) => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/booking/${id}/view`,
      {
        requiresAccessToken: true
      }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//update kyc list
export const handleupdateKyc = async (formData) => {
 
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/update/kyc`,
      formData,
      {
        requiresAccessToken: true
      }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//notification list
export const Notificationlist = async ({ page }) => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/notification/list?page=${page}`,
      {
        requiresAccessToken: true
      }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//kyc list
export const getKycList = async () => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/kyc/list`,
      {
        requiresAccessToken: true
      }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
// change password
export const Changepassword = async ({data}) => {
  try {
    const response = await axios.post(`${REACT_APP_BASE_URL}/change-password?old_password=${data?.old}&password=${data?.new}&password_confirmation=${data?.confrm}`
    ,{ requiresAccessToken: true });
    return response.data;
  } catch (error) {
   
    throw error;
  }
};

//confirm Booking
export const confirmBooking = async (data) => {
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/confirm/booking`,
      data,
      {
        requiresAccessToken: true
      }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
//
export const rescheduleBooking = async (data) => {
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/booking-reschedule`,
      data,
      {
        requiresAccessToken: true
      }
    );
    return response.data;
  } catch (error) {
    
    throw error;
  }
};
// Add more API service functions as needed

