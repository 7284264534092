import React, { useState, useEffect } from 'react';
import '../components/service/assets/Style/style.css';
import '../components/service/assets/Style/responcive.css';
import AppHeader from '../components/Common/Header/AppHeader';
import Categorylisting from '../components/service/Categorylisting';
import ListingHeader from '../components/service/ListingHeader';
import ListingSortBy from '../components/service/ListingSortBy';
import ListingCard from '../components/service/ListingCard';
import AppFooter from '../components/Common/AppFooter';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ListingFirm from '../components/service/ListingFirm';
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { fetchSearchResult } from '../store/SearchServices';
import { fetchFirmResult } from '../store/SearchFirm';

function ServiceProvider() {
    const dispatch = useDispatch();
    const { loading, providerList, totalRecords, per_page } = useSelector((state) => state.search);
    const { loader, firmList, firmlength } = useSelector((state) => state.firm_list);
    const {
        selectedCategory,
        selectedSubCategory,
        selectedSpeciality,
        searchDistance,
        appoinmentDate,
        providerType,
        sortBy
    } = useSelector((state) => state.filterdata_list);
    const [currentPage, setCurrentPage] = useState(1);
    const { currentPlace } = useSelector((state) => state.Homefilter);
    const totalPages = Math.ceil(totalRecords / per_page);
    useEffect(() => {
        handlePageChange()
    }, [])
    const handlePageChange = (data) => {
        const dateObject = new Date(appoinmentDate);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-IN', options).replace(/\//g, '-');
        setCurrentPage(data)
        if (providerType === 2) {
            dispatch(fetchFirmResult({
                selectedCategory: selectedCategory?.value,
                selectedLatitude: currentPlace?.latitude,
                selectedLongitude: currentPlace?.longitude,
                searchDistance: searchDistance,
                appoinment_Date: formattedDate,
                sortBy: sortBy,
                page: currentPage
            }))
        } else {
            dispatch(fetchSearchResult({
                selectedCategory: selectedCategory?.value,
                selectedSubCategory: selectedSubCategory?.value,
                selectedSpecialty: selectedSpeciality?.value,
                selectedLatitude: currentPlace?.latitude,
                selectedLongitude: currentPlace?.longitude,
                searchDistance: searchDistance,
                appoinment_Date: formattedDate,
                page: currentPage
            }))
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);
    return (
        <>
            <div className="listing-service-main">
                <AppHeader />
                <div className="mainP HomePage">
                    <div className="list_page_layout">
                        <div className="listing-main">
                            <div className="wrapper">
                                <Categorylisting />
                                <div className="listing-header">
                                    <div className="row" style={{ alignItems: 'center' }}>
                                        <div className="col-6 header-brudcrumb-i-listing">
                                            <h6>Showing {providerType == 1 ? providerList?.length : firmlength} Results</h6>
                                        </div>
                                        <ListingHeader />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-3 pt-3">
                                        <ListingSortBy />
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-9 main-detain-div">

                                        <div className="row margin-change">
                                            {loading || loader ? (

                                                <div className='row loading-image'>
                                                    <Skeleton count={4} height={200} style={{ width: "423px", marginRight: "10px" }} />
                                                </div>

                                            ) : (

                                                providerType == 1 ? (
                                                    providerList?.length > 0 ? (
                                                        providerList.map((provider, index) => (
                                                            <ListingCard key={index} data={provider} />
                                                        ))
                                                    ) : <div className='no_data_msg'>
                                                        <p>Sorry, we couldn't find any matching results for your search at the moment.!</p>
                                                    </div>
                                                ) : (firmList?.length > 0 ? (
                                                    firmList.map((provider, index) => (
                                                        <ListingFirm key={index} data={provider} />
                                                    ))
                                                ) : (
                                                    <div className='no_data_msg'>
                                                        <p>Sorry, we couldn't find any matching results for your search at the moment.!</p>
                                                    </div>
                                                ))
                                            )}
                                        </div>
                                        <div className='row'>
                                            <ResponsivePagination
                                                current={currentPage}
                                                total={totalPages}
                                                onPageChange={handlePageChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <AppFooter />
            </div>

        </>
    )
}

export default ServiceProvider
