import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { availableSlots } from "../services/ApiServices"


export const fetchAvailabilityData = createAsyncThunk(
  "filters/fetchAvailabilityData",
  async (params) => {
    const data = await availableSlots(params);
        return data
      }
    );
const AvailabilitySlice = createSlice({
  name: "AvailabilitySearch",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailabilityData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAvailabilityData.fulfilled, (state, action) => {
        state.availableSlots=action.payload.Data;
        state.sloteMsg=action.payload.Message;
        state.loading = false;
      })
      .addCase(fetchAvailabilityData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
 } = AvailabilitySlice.actions;

export default AvailabilitySlice.reducer;