import React from 'react';

const AuthTitle = ({ title }) => {
  return (
    <div className="authTitle">
      <h2>{title}</h2>
    </div>
  );
};

export default AuthTitle;
