import React, { useEffect, useState } from 'react'
import AppHeader from '../components/Common/Header/AppHeader'
import AppFooter from '../components/Common/AppFooter'
import '../components/Account/asset/Mybookings.css'
import '../components/Account/asset/Bookingsresponsive.css'
import AccountSideBar from '../components/Account/AccountSideBar'
import man from '../assets/images/man.jpg'
import { cancelBooking, getbookingview, refundBookingAmount } from '../services/ApiServices'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
const BookingView = () => {
    const { userInfo } = useSelector((state) => state.userDetails);

    const [bookinglist, setbookinglist] = useState([])
    const [confirmCancel, setconfirmCancel] = useState(false)
    const navigate = useNavigate()
    const id = useParams()

    useEffect(() => {
       
        getbookings()
    }, [])
   
    const getbookings = async () => {
        try {
            const response = await getbookingview({id:id.id, userInfo: userInfo });
            setbookinglist(response.Data)
        } catch (error) {
            
        }
    }
  
    const handlemapview = () => {
        // Construct the URL with the latitude and longitude
        const mapUrl = `https://www.google.com/maps?q=${bookinglist?.service_provider_latitude},${bookinglist?.service_provider_longitude}`;
        // Open a new tab with the map URL
        window.open(mapUrl, '_blank');
      };
const handleCancelbooking=async()=>{
    try {
        const response = await cancelBooking({id:id.id, userInfo: userInfo });
        if(response.Status == 1){
            toast.success(response.Message)
            setconfirmCancel(false)
            refundgenerate(response.Data)
        }
    } catch (error) {
        
    }
}
const refundgenerate=async(data)=>{
    try {
        const response = await refundBookingAmount({payid:data?.razorpay_payment_id, amnt: data?.amount });
        if(response.status == 1){
            toast.success(response.message)
            setTimeout(() => {
                navigate('/booking-list')
            }, 1500);
            // 
        }
    } catch (error) {
        
    }
}
  return (
    <div>
    <AppHeader/>
    <div className="bookingdetail" style={{ background: "#f5f5f5", padding: "30px 0 50px" }}>

        <div className="wrapper">
            <div className="row">
               <AccountSideBar page="bookings"/>
                <div className="col-12 col-lg-9">
                    <div className="my-account" style={{ background: "transparent", boxShadow: "unset", paddingTop: "0" }}>
                        <h2>Booking Details </h2>
                        <div className="bookigs-details-single">
                            <div className="row">
                                <div className="col-12 col-lg-3">
                                    <div className="individual-img">
                                        <img src={bookinglist? bookinglist?.service_provider_image_url: man}></img>
                                        <h3 style={{ marginBottom: "5px", wordBreak: "break-all" }}>{bookinglist?.provider_type ==1 ? bookinglist?.service_provider_name:bookinglist?.firm_name}</h3>
                                        <span style={{ marginBottom: "5px" }}>{bookinglist?.service_provider_designation}</span>
                                        <div className="row">
                                            <div className="col-1">
                                                <i class="fa-solid fa-location-dot" style={{ color: "rgb(170 166 166)", fontSize: "12px" }}></i></div>
                                            <div className="col-9">
                                                <p style={{ color: "rgb(170 166 166)", fontSize: "14px", marginBottom: "0", paddingBottom: "0", lineHeight: "1.9", textTransform: "capitalize" }}>{bookinglist?.location_name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="row description-main">
                                        <div className="col-12 col-lg-6"><div className={bookinglist?.booking_status_id ==2 ?"badge":"notapprbadge"}>{bookinglist?.booking_status_name}</div></div>
                                    </div>
                                    <div className="row description-main" style={{ justifyContent: "center" }}>
                                        <div className="col-5" >Date & Time  </div>
                                        <div className="col-1">:</div>
                                        <div className="col-5">{bookinglist?.date}, &nbsp;{bookinglist?.time_from}-{bookinglist?.time_from}</div>
                                    </div>
                                    <div className="row description-main" style={{ justifyContent: "center" }}>
                                        <div className="col-5" >Booked For</div>
                                        <div className="col-1">:</div>
                                        <div className="col-5">{bookinglist?.booked_for}</div>
                                    </div>
                                    <div className="row description-main" style={{ justifyContent: "center" }}>
                                        <div className="col-5" >Booking Charge</div>
                                        <div className="col-1">:</div>
                                        <div className="col-5"> ₹ {bookinglist?.amount}</div>
                                    </div>
                                    <div className="row description-main" style={{ justifyContent: "center" }}>
                                        <div className="col-5">Booking Id</div>
                                        <div className="col-1">:</div>
                                        <div className="col-5">{bookinglist?.booking_number}</div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3">
                                    <button className='details' onClick={()=>setconfirmCancel(true)}>Cancel Booking</button>
                                    <button  style={{ background: "#bbb8b8", border: "1px solid #bbb8b8" , width:"100%",padding:"5px", borderRadius:"8px"}} onClick={handlemapview}>Get Direction</button>
                                </div>
                            </div>
                        </div>
                        <button className='back' onClick={()=>navigate('/booking-list')}>Back</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
     <AppFooter/>  
     {confirmCancel && (
                <div className="updateSuccess popup-login">
                    <div>
                        <p>Are you sure you want to cancel?</p>
                        <div className="yN">
                            <button onClick={()=>setconfirmCancel(false)}>Cancel</button>
                            <button onClick={handleCancelbooking}>Confirm</button>
                        </div>
                    </div>
                </div>
            )}  
</div>
  )
}

export default BookingView