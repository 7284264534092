import React, { useState, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountDetail, createBooking } from "../../services/ApiServices";
import { setEditMember,setBookingSummury } from "../../store/userSlice";
import { fetchFamilylist } from "../../store/FamilymemberList";
import { useParams,useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Membercard() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { myFamily } = useSelector((state) => state.familyList);
    const [myProfile, setMyProfile] = useState()
    const [familySlot,setFamilySlot] = useState()
    const { selectedSlot } = useSelector((state) => state.filterdata_list)
    const providerId = useParams();
    useEffect(() => {
        getmyProfile()
        dispatch(fetchFamilylist())
    }, [])
    const getmyProfile = async () => {
        try {
            const response = await getAccountDetail();
            setMyProfile(response.Data)
        } catch (error) {
            
        }
    }
    const EditFamily=(data)=>{
        dispatch(setEditMember(data))
        navigate('/edit-family-membar')
    }
    const handleBooking=async()=>{
        dispatch(setBookingSummury())
        var newdate = new Date(selectedSlot?.date);
        // Get the day, month, and year
        var day = newdate.getDate();
        var month = newdate.getMonth() + 1; // Months are zero-based, so we add 1
        var year = newdate.getFullYear();
        // Format the date to 'dd-mm-yyyy' format
        var formattedDate = (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;
        if(!familySlot){
          
            toast.warning('select family member')
        }else{
            const data ={
                service_provider_id:providerId?.id,
                family_id:familySlot=='myself'?'':familySlot,
                time_slot_id:selectedSlot?.id,
                date:formattedDate
            }
            try {
                const response = await createBooking(data);
                toast.success(response.Message)
                dispatch(fetchFamilylist())
                dispatch(setBookingSummury(response))
                navigate('/booking-summury/'+providerId?.id)
            } catch (error) {
                
                // Add logic for handling login failure (e.g., show error message)
            }
        }
    }
    return (
        <div className="booking-detail-main" style={{ padding: "20px" }}>
            <div className="booking-detail-inner">
                <div className="booking-member">
                    <div className="row person-details">
                        <div className="col-1">
                            <div className="bakground-checkbox"><label className="containerr">
                                <input type="radio" name="radio"  onChange={()=>setFamilySlot('myself')}/>
                                <span className="checkmark"></span>
                            </label></div>
                        </div>
                        <div className="col-8" style={{ paddingLeft: "0" }}><h3 style={{ color: 'green' }}>Myself</h3></div>
                    </div>
                    <div className="row description-main">
                        <div className="col-5">DOB  </div>
                        <div className="col-2">:</div>
                        <div className="col-5">{myProfile?.dob}</div>
                    </div>
                    <div className="row description-main">
                        <div className="col-5">Gender  </div>
                        <div className="col-2">:</div>
                        <div className="col-5">{myProfile?.customer_gender?.name}</div>
                    </div>
                    <div className="row description-main">
                        <div className="col-5">Email  </div>
                        <div className="col-2">:</div>
                        <div className="col-5">{myProfile?.customer_email}</div>
                    </div>
                    <div className="row description-main">
                        <div className="col-5">Mobile  </div>
                        <div className="col-2">:</div>
                        <div className="col-5">{myProfile?.customer_mobile}</div>
                    </div>
                </div>
                {myFamily?.map((member, index) => {
                    return(
                    <div className="booking-member" key={index}>
                        <div className="row person-details">
                            <div className="col-1">
                                <div className="bakground-checkbox">
                                    <label className="containerr">
                                        <input type="radio" name="radio" onChange={()=>setFamilySlot(member?.id)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-8" style={{ paddingLeft: "0" }}>
                                <h3>{member?.name}</h3>
                            </div>
                            <div className="col-2">
                                <h3 style={{ cursor: "pointer", fontSize: 12 }} onClick={()=>EditFamily(member)}>Edit</h3>
                            </div>
                        </div>
                        <div className="row description-main">
                            <div className="col-5">DOB</div>
                            <div className="col-2">:</div>
                            <div className="col-5">{member?.dob}</div>
                        </div>
                        <div className="row description-main">
                            <div className="col-5">Gender</div>
                            <div className="col-2">:</div>
                            <div className="col-5">{member.gender?.name}</div>
                        </div>
                        <div className="row description-main">
                            <div className="col-5">Email</div>
                            <div className="col-2">:</div>
                            <div className="col-5">{member?.email}</div>
                        </div>
                        <div className="row description-main">
                            <div className="col-5">Mobile</div>
                            <div className="col-2">:</div>
                            <div className="col-5">{member?.mobile}<br/>
                                    {member?.is_otp_verified==1?<p style={{fontSize:10,color:'green'}}>mobile verified</p>:<p style={{fontSize:10,color:'red'}}>mobile not verified</p>}
                            </div>
                        </div>
                    </div>
                )})}

            </div>
            <button className="addmemeber" style={{ width: "100" }} onClick={() => handleBooking()}>Book now</button>
        </div>
    )
}

export default Membercard
