import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchSubcategoryDatas,
    fetchSpecialityDatas,
    setSelectedCategory,
    setSelectedSubCategory,
    setSelectedSpeciality,
    setDistance,
    setAppoinmentDate,
    setProviderType
} from '../../store/FilterSliceList';
import { setCurrentplace } from '../../store/HomeSlice';
import { fetchSearchResult } from '../../store/SearchServices';
import { useNavigate } from 'react-router-dom';
import { fetchFirmResult } from '../../store/SearchFirm';

function ListingSortBy() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        categoryData,
        subcategoryData,
        specialityData,
        selectedCategory,
        selectedSubCategory,
        selectedSpeciality,
        searchDistance,
        appoinmentDate,
        providerType,
        firmcategoryData,
        sortBy } = useSelector((state) => state.filterdata_list);

    const { currentPlace } = useSelector((state) => state.Homefilter);
    const { loading } = useSelector((state) => state.search);

    const handlePlaceSelect = async (place) => {
        try {
            const results = await geocodeByPlaceId((place.value.place_id ? place.value.place_id : place).toString());
            const latitude = results[0].geometry.location.lat();
            const longitude = results[0].geometry.location.lng();
            const apiKey = 'AIzaSyBTCvNcsYo-DUA3bbtJJkQ_FHm-0y4eeWQ';
            getAddressFromCoordinatesWithRetry(latitude,longitude,apiKey)
        } catch (error) {
        }
    };
    function getAddressFromCoordinatesWithRetry(latitude, longitude, apiKey, retries = 3, delay = 1000) {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
        
        const fetchWithRetry = (retryCount) => {
            fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.results && data.results.length > 0) {
                        const placeName = data.results[0].formatted_address;
                      
                        dispatch(setCurrentplace({ latitude, longitude, placeName }))
              
                        // Do something with the address
                    } else {
                        console.log("Address not found");
                    }
                })
                .catch(error => {
                    console.error("Error fetching address:", error);
                    if (retryCount > 0) {
                        setTimeout(() => fetchWithRetry(retryCount - 1), delay);
                    }
                });
        };
    
        fetchWithRetry(retries);
    }
    const search = () => {
        const dateObject = new Date(appoinmentDate);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-IN', options).replace(/\//g, '-');

        dispatch(setProviderType(1))
        dispatch(fetchSearchResult({
            selectedCategory: selectedCategory?.value,
            selectedSubCategory: selectedSubCategory?.value,
            selectedSpecialty: selectedSpeciality?.value,
            selectedLatitude: currentPlace?.latitude,
            selectedLongitude: currentPlace?.longitude,
            searchDistance: searchDistance,
            appoinment_Date: formattedDate
        }))
    };
    const searchFirm = () => {
        dispatch(setProviderType(2))
        const dateObject = new Date(appoinmentDate);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-IN', options).replace(/\//g, '-');
        dispatch(fetchFirmResult({
            selectedCategory: selectedCategory?.value,
            selectedLatitude: currentPlace?.latitude,
            selectedLongitude: currentPlace?.longitude,
            searchDistance: searchDistance,
            appoinment_Date: formattedDate,
            sortBy: sortBy
        }))
    }
    const handleChangeCategory = (selectedOption) => {
        dispatch(fetchSubcategoryDatas(selectedOption?.value))
        dispatch(setSelectedCategory(selectedOption))
        if (subcategoryData?.length <= 0) {
            dispatch(fetchSpecialityDatas({
                category_id: selectedOption?.value,
                subcategory_id: ''
            }))
        }
    };
    const handleChangeSubCategory = (selectedOption) => {
        dispatch(fetchSpecialityDatas({
            category_id: '',
            subcategory_id: selectedOption?.value
        }))
        dispatch(setSelectedSubCategory(selectedOption))
    };
    const handleChangeSpeciality = (selectedOption) => {
        dispatch(setSelectedSpeciality(selectedOption))
    };
    return (
        <>
            {providerType == 2 ?
                <div className="listing-search">
                    <div className="left-sidebar_listing sticky"
                        style={{ padding: '10px', paddingTop: 0, textAlign: 'center' }}>
                        <h2>Modify &amp; Search</h2>
                    </div>
                    <form action="">
                        <div className="form-c">
                            <div className='col-12'>
                                <GooglePlacesAutocomplete
                                    apiKey="AIzaSyBTCvNcsYo-DUA3bbtJJkQ_FHm-0y4eeWQ"
                                    selectProps={{
                                        placeholder: currentPlace?.placeName,
                                        onChange: handlePlaceSelect,
                                    }}

                                    query='en'
                                    inputStyle='left'
                                />
                            </div>
                            <div className='col-12'>
                                <Select
                                    value={selectedCategory ? selectedCategory : null}
                                    options={firmcategoryData}
                                    isSearchable
                                    placeholder="Category"
                                    onChange={handleChangeCategory}
                                />
                            </div>
                            <input type='text' placeholder='Distance' value={searchDistance ? searchDistance : ''} onChange={(e) => dispatch(setDistance(e.target.value))} className='searchform_distance' />
                            <button
                                className="pos-but-loader" type='button' onClick={() => searchFirm()}>Search</button>
                        </div>
                    </form>
                </div>
                :
                <div className="listing-search">
                    <div className="left-sidebar_listing sticky"
                        style={{ padding: '10px', paddingTop: 0, textAlign: 'center' }}>
                        <h2>Modify &amp; Search</h2>
                    </div>
                    <form action="">
                        <div className="form-c">
                            <div className='col-12'>
                                <GooglePlacesAutocomplete
                                    apiKey="AIzaSyBTCvNcsYo-DUA3bbtJJkQ_FHm-0y4eeWQ"
                                    selectProps={{
                                        placeholder: currentPlace?.placeName,
                                        onChange: handlePlaceSelect,
                                    }}

                                    query='en'
                                    inputStyle='left'
                                />
                            </div>
                            <div className='col-12'>
                                <Select
                                    value={selectedCategory ? selectedCategory : null}
                                    options={categoryData}
                                    isSearchable
                                    placeholder="Category"
                                    onChange={handleChangeCategory}
                                />
                            </div>
                            <div className='col-12'>
                                {subcategoryData?.length > 0 && <Select
                                    options={subcategoryData}
                                    value={selectedSubCategory ? selectedSubCategory : null}
                                    isSearchable
                                    placeholder="Subcategory"
                                    onChange={handleChangeSubCategory}
                                />}
                            </div>
                            <div className='col-12'>
                                <Select
                                    options={specialityData}
                                    value={selectedSpeciality ? selectedSpeciality : null}
                                    isSearchable
                                    placeholder="Speciality"
                                    onChange={handleChangeSpeciality}
                                />
                            </div>
                            <input type='text' placeholder='Distance' value={searchDistance ? searchDistance : ''} onChange={(e) => dispatch(setDistance(e.target.value))} className='searchform_distance' />
                            <DatePicker
                                showIcon
                                selected={appoinmentDate ? new Date(appoinmentDate) : new Date()}
                                placeholderText="Appoinment date"
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => dispatch(setAppoinmentDate(date))}
                            />
                            <button
                                className="pos-but-loader" type='button' onClick={() => search()}>Search</button>
                        </div>
                    </form>
                </div>
            }
        </>
    )
}

export default ListingSortBy
