import React from 'react';
import vector from "../../assets/images/Vector (1).png";
import { useLocation,useNavigate } from 'react-router-dom';

function Confirm() {
    const location = useLocation()
    const navigate = useNavigate()
    const data = location.state.data;
  return (
    <>
            <div className="booking-confirmed-main">
                <img src={vector}></img>
                <h2>Booking confirmed</h2>
                <p>Congrats {data?.message}</p>

                <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className="name-details">
                            <h4>{data?.service_provider_name}</h4>
                            <p style={{ marginBottom: "20px", textAlign: "left", fontSize: "18px" }}>{data?.service_provider_designation}</p>
                            <div className="row description" style={{ fontSize: "13px", paddingBottom: "5px" }}>
                                <div className="col-5">Booked for      </div>
                                <div className="col-2">:</div>
                                <div className="col-5">{data?.booked_for}</div>
                            </div>
                            <div className="row description" style={{ fontSize: "13px", paddingBottom: "5px" }}>
                                <div className="col-5">Mobile </div>
                                <div className="col-2">:</div>
                                <div className="col-5">{data?.mobile_number}</div>
                            </div>
                            <div className="row description" style={{ fontSize: "13px", paddingBottom: "5px" }}>
                                <div className="col-5">Email  </div>
                                <div className="col-2">:</div>
                                <div className="col-5">{data?.email}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="date">
                            <h4>{data?.date}</h4>
                            <h4 style={{ marginBottom: "30px" }}>{data?.time}</h4>
                            <h4 style={{ fontSize: "12px", borderBottom: "1px solid #fff", paddingBottom: "5px" }}>Booking ID</h4>
                            <h4>{data?.booking_number}</h4>
                        </div>
                        
                    </div><div className="row description" style={{ marginTop: "30px" }}>
                            <div className="col-12 col-lg-6 button-edit" style={{ justifyContent: "right", display: "flex" }}>
                                <button className="edit" style={{border:"1px solid #D2042D",background:"transparent",color:"#D2042D"}} onClick={()=>navigate('/booking-list')}>View Booking</button>
                            </div>
                            <div className="col-12 col-lg-6 button-confirm" style={{ justifyContent: "left", display: "flex" }}>
                                <button className="edit" onClick={()=>navigate('/')}>Back To Home</button>
                            </div>
                        </div>
                </div>
            </div>
        </>
  )
}

export default Confirm
