import React, { useState } from 'react';
import rectangle from '../../assets/images/Rectangle 8.png';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
import { confirmBooking } from '../../services/ApiServices';
import { toast } from 'react-toastify';

function DetailsSummury() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const providerId = useParams();
    const [Razorpay] = useRazorpay();
    const [code, setcode] = useState()
    const [isChecked, setIsChecked] = useState(false);
    const { bookingSummury } = useSelector((state) => state.userDetails);

    const handleReferralCode=()=>{
        if(isChecked){
            if (code) {
                handlePayment()
            }
            else{
                toast.warn("Please enter referral code")
            }
        }
        else{
            handlePayment()
        }
    }
    const handlePayment = async (params) => {
        const options = {
            key: "rzp_test_RAE4hkHVFo6z50", // Enter the Key ID generated from the Dashboard
            amount: bookingSummury?.Data?.payment_details?.booking_charge,
            currency: bookingSummury?.Data?.razor_order?.currency,
            name: "Zimple Q",
            description: "Test Transaction",
            image: "https://example.com/your_logo",
            order_id: bookingSummury?.Data?.razor_order?.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {
                verifyPayment(response)
            },
            theme: {
                color: "#3399cc",
            },
        };
        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    };
    const verifyPayment = async (response) => {
        const data = {
            service_provider_id: bookingSummury?.Data?.service_provider_details?.service_provider_id,
            family_id: bookingSummury?.Data?.customer_details?.family_id,
            date: bookingSummury?.Data?.customer_details?.date,
            time_slot_id: bookingSummury?.Data?.customer_details?.timeslot_id,
            razorpay_payment_id: response?.razorpay_payment_id,
            razorpay_order_id: response?.razorpay_order_id,
            razorpay_signature: response?.razorpay_signature,
            customer_provider_reference_numberr: code ? code : ''
        }
        try {
            const response = await confirmBooking(data);
            if(response.Status==1){
                toast.success(response.Message)
                setTimeout(() => {
                    navigate('/booking-confirmed',{state:{data:response.Data}});
                }, 1500);
            }else{
                toast.warning(response.Message)
            }

        } catch (error) {
            
        }
    }
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        // Reset input value and error when checkbox is unchecked
        if (!event.target.checked) {
          setcode();
        //   setInputError();
        }
      };
    return (
        <>
            <div className="bookingsummary-main">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className="booking">
                            <h2>Booking Summary</h2>
                            <div className="row description">
                                <div className="col-5">Date & Time </div>
                                <div className="col-2">:</div>
                                <div className="col-5">{bookingSummury?.Data?.customer_details?.date_and_time}</div>
                            </div>
                            <div className="row description">
                                <div className="col-5">Booked for </div>
                                <div className="col-2">:</div>
                                <div className="col-5">{bookingSummury?.Data?.customer_details?.booked_for}</div>
                            </div>
                            <div className="row description">
                                <div className="col-5">Gender  </div>
                                <div className="col-2">:</div>
                                <div className="col-5">{bookingSummury?.Data?.customer_details?.gender?.name}</div>
                            </div>
                            <div className="row description">
                                <div className="col-5">Email   </div>
                                <div className="col-2">:</div>
                                <div className="col-5">{bookingSummury?.Data?.customer_details?.email}</div>
                            </div>

                            <div className="row description">
                                <div className="col-5">Mobile   </div>
                                <div className="col-2">:</div>
                                <div className="col-5"> {bookingSummury?.Data?.customer_details?.mobile_number}</div>
                            </div>
                          
                            <div className="row description" style={{ fontWeight: "700" }}>
                                <div className="col-5">Booking Amount</div>
                                <div className="col-2">:</div>
                                <div className="col-5">₹ {bookingSummury?.Data?.payment_details?.booking_charge}</div>
                            </div> 
                             <div className="row description">
                                <div className="col-5">Consultation Fee</div>
                                <div className="col-2">:</div>
                                <div className="col-5">₹ {bookingSummury?.Data?.payment_details?.consultation_fee}</div>
                            </div>
                            <div className="row description" style={{ marginTop: "30px" }}>
                                {/* <div className="col-12 col-lg-6 button-edit" style={{ justifyContent: "right", display: "flex" }}>
                            <button className="edit">Edit Booking</button>
                        </div> */}      
                         <div className="row description">
                                        <p style={{fontSize:"12px"}}><span style={{color:"red" , fontSize:"15px"}}>* </span> Please note that only the booking charge has been processed at this time. The consultation fee will be payable at the time of consultation.</p>
                                        </div>       
                                <div className="col-12 col-lg-12 button-confirm" style={{ justifyContent: "center", display: "flex" }}>
                                    <button className="confirm" type='button' onClick={handleReferralCode}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="booking-detail">
                            <img src={bookingSummury?.Data?.service_provider_details?.service_provider_image}></img>
                            <h4>{bookingSummury?.Data?.service_provider_details?.service_provider_name}</h4>
                            <p>{bookingSummury?.Data?.service_provider_details?.service_provider_designation}</p>
                            <div className="row">
                                <div className="col-1">
                                    <i className="fa-solid fa-location-dot" style={{ color: "rgb(170 166 166)", fontSize: "12px" }}></i></div>
                                <div className="col-11">
                                    <p style={{ color: "rgb(170 166 166)", fontSize: "14px", marginBottom: "0", paddingBottom: "0", lineHeight: "1.9" }}>{bookingSummury?.Data?.service_provider_details?.service_provider_address}</p>
                                </div>
                            </div>
                        </div>
                            <div >
                                <input type="checkbox"  checked={isChecked} onChange={handleCheckboxChange}/> Do you have a Referral Code
                                {isChecked &&
                                <div className='form'>
                                    <label>Referal Code:</label>
                                <input type='text'
                                value={code}
                                
                                onChange={(e)=>setcode(e.target.value)}
                                />
                                </div>
                            }
                                
                            </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailsSummury
