import React, { useState, useEffect } from 'react';
import svgrepo from "../../components/service/assets/Images/filter-svgrepo-com 3.png";
import { useSelector, useDispatch } from 'react-redux';
import { setSortBy } from '../../store/FilterSliceList';
import { fetchSearchResult } from '../../store/SearchServices';
import { fetchFirmResult } from '../../store/SearchFirm';

function ListingHeader() {
    const dispatch = useDispatch()
    const { providerType, sortBy } = useSelector((state) => state.filterdata_list);
    const {
        selectedCategory,
        selectedSubCategory,
        selectedSpeciality,
        searchDistance,
        appoinmentDate } = useSelector((state) => state.filterdata_list);
    const { currentPlace } = useSelector((state) => state.Homefilter);
    const { providerList } = useSelector((state) => state.search);
    const { totalRecords } = useSelector((state) => state.firm_list);
    const [modalVisible, setModalVisible] = useState(false);
    const closeModal = () => {
        setModalVisible(false);
    };
    const firmlisting = (e) => {
        dispatch(setSortBy(e.target.value))
        const dateObject = new Date(appoinmentDate);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-IN', options).replace(/\//g, '-');
        dispatch(fetchFirmResult({
            selectedCategory: selectedCategory?.value,
            selectedLatitude: currentPlace?.latitude,
            selectedLongitude: currentPlace?.longitude,
            searchDistance: searchDistance,
            appoinment_Date: formattedDate,
            sortBy: e.target.value
        }))
    }
    const servicelisting = (e) => {
        dispatch(setSortBy(e.target.value))
        const dateObject = new Date(appoinmentDate);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-IN', options).replace(/\//g, '-');
        dispatch(fetchSearchResult({
            selectedCategory: selectedCategory?.value,
            selectedSubCategory: selectedSubCategory?.value,
            selectedSpecialty: selectedSpeciality?.value,
            selectedLatitude: currentPlace?.latitude,
            selectedLongitude: currentPlace?.longitude,
            searchDistance: searchDistance,
            appoinment_Date: formattedDate,
            sortBy: e.target.value
        }))
    }

    return (
        <>
            
                    <div className="col-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <button className="btn btn-primary" type="button" onClick={() => setModalVisible(true)} aria-controls="offcanvasExample" style={{ marginBottom: '5px' }}>
                            Filter
                            <img src={svgrepo} style={{ width: '20px', height: 'auto', objectFit: 'cover', marginLeft: '5px' }} />
                        </button>
                    </div>
                
            <div className={`offcanvas offcanvas-start${modalVisible ? ' show' : ''}`} tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div className="row">
                    <div className="col-12">
                        <div className="wrapper">
                            <div className="left-sidebarxx sticky">
                                <div className="div" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h2 className='mt-3'>Filters by</h2>
                                    <i className="fa-solid fa-xmark" onClick={closeModal}
                                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}></i>
                                </div>
                            </div>

                            <div className="filter-box">
                            </div>
                            <div className="filter-box">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {/* <h3>Categories</h3> */}
                                </div>

                                {providerType === 2 ?
                                    <ul>
                                        <li>
                                            <label>
                                                <input type="radio" name="main" value="4" checked={sortBy && sortBy === '4'} onChange={firmlisting} />
                                                <span>Alphabetical Asc (A to Z)</span>
                                            </label>
                                        </li>
                                        <li>
                                            <label><input type="radio" name="main" value="5" checked={sortBy && sortBy === '5'} onChange={firmlisting} /><span>Alphabetical Dsc (Z to A)</span></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" name="main" value="6" checked={sortBy && sortBy === '6'} onChange={firmlisting} /><span>Distance Asc</span></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" name="main" value="7" checked={sortBy && sortBy === '7'} onChange={firmlisting} /><span>Distance Dsc</span></label>
                                        </li>
                                    </ul> :
                                    <ul>
                                        <li>
                                            <label><input type="radio" name="main" value="4" checked={sortBy && sortBy === '4'} onChange={servicelisting} /><span>Alphabetical Asc (A to Z)</span></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" name="main" value="5" checked={sortBy && sortBy === '5'} onChange={servicelisting} /><span>Alphabetical Dsc (Z to A)</span></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" name="main" value="2" checked={sortBy && sortBy === '5'} onChange={servicelisting} /><span>Booking Count (Low to High)</span></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" name="main" value="3" checked={sortBy && sortBy === '3'} onChange={servicelisting} /><span>Booking Count (High to Low)</span></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" name="main" value="6" checked={sortBy && sortBy === '6'} onChange={servicelisting} /><span>Distance Asc</span></label>
                                        </li>
                                        <li>
                                            <label><input type="radio" name="main" value="7" checked={sortBy && sortBy === '7'} onChange={servicelisting} /><span>Distance Dsc</span></label>
                                        </li>
                                    </ul>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListingHeader
