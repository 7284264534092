import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import SingleSpeciality from './SingleSpeciality';


const FeaturedSpeciality = ({ title1, specialities }) => {
  const responsiveOptions = {
    0: { items: 2, nav: false, dots: false },
    960: { items: 5, nav: false, dots: true },
    600: { items: 4, nav: false, dots: true },
    600: { items: 3, nav: false, dots: true },
  };
  const navigate = useNavigate()
const handlespeciality=()=>{
  navigate('/specialities',{
    state:{
      specialities: specialities
    }
  })
}
const spliteddata = specialities.slice(0, 6);
  return (
    <div className="specialities">
      <div className="wrapper">
        <div className="title-sec-s">
          <h2 className="tile-h sp-h">{title1}</h2>
          <button onClick={handlespeciality} className="ssd ">
            View All Specializations
          </button>
        </div>
        <div className='row'>
        
            {spliteddata?.map((item, index) => (
              <SingleSpeciality key={index} item={item} />
            ))}
            </div>
  
        </div>
      </div>
   
  );
};

export default FeaturedSpeciality;
