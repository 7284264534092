import React from 'react';
import { Link } from 'react-router-dom';

const FooterMenu = ({ title, menu }) => {
  return (
    <div className="menu_footer_block">
      <h3>{title}</h3>
      <ul>
        {menu?.map((item, index) => (
          <li key={index}>
            <Link to={{ pathname: `/${item.link}` }}>{item.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterMenu;
