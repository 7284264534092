import React, { useState } from 'react'
import AccountSideBar from './AccountSideBar'
import TextField from '@mui/material/TextField';
import "./asset/Changepassword.css"
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Changepassword } from '../../services/ApiServices';
import { toast } from 'react-toastify';

function ChangePassword() {
    const RequiredLabel = ({ labelText }) => (
        <div>
            <Typography component="span">{labelText}</Typography>
            <Typography component="span" style={{ color: 'red', fontSize: "20px" }}>
                *
            </Typography>
        </div>
    );
    const [old, setold] = useState(false)
    const [newpass, setnewpass] = useState(false)
    const [confm, setconfm] = useState(false)
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    const schema = yup.object().shape({

        oldpassword: yup.string().required("Old password is required"),
        newpassword: yup.string().required("New password is required").matches(regex, "Password must be strong"),
        confirmpassword: yup.string().required('Confirm password is required').oneOf([yup.ref("newpassword"), null], "Password does not match"),
    })
    const { register, handleSubmit, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            oldpassword: "",
            newpassword: "",
            confirmpassword: "",

        },
    })
    const handlepassword = async () => {
        const data = {
            old: getValues().oldpassword,
            new: getValues().newpassword,
            confrm: getValues().confirmpassword
        }
        try {
            const response = await Changepassword({ data });

            if (response.Status == 1) {
                toast.success(response.Message)
            } else {
                toast.warn(response.Message)
            }

        } catch (error) {

        }
    }
    return (
        <div className="row">
            <AccountSideBar page="password" />
            <div className="col-12 col-lg-9">
                <div className="my-accountt">
                    <h2 style={{ marginTop: "20px" }}> Change Password </h2>
                    <form onSubmit={handleSubmit(handlepassword)}>
                        <div className="row">
                            <div className="col-12 col-lg-8">
                                <div className="favorates-main">
                                    <div className="row textfield-row-main" style={{ marginBottom: "30px", position: "relative" }}>
                                        <div className="col-12 changpswd">
                                            <TextField
                                                id="standard-basic"
                                                label={<RequiredLabel labelText="Current Password" />}
                                                variant="standard"
                                                {...register("oldpassword")}
                                                type={old ? "text" : "password"}
                                            />
                                            {old ? <i class="fa-solid fa-eye grey-open" onClick={() => setold(!old)}></i> :
                                                <i class="fa-solid fa-eye-slash grey-open" onClick={() => setold(!old)}></i>
                                            }

                                        </div>
                                        <span className="errorspan">{errors.oldpassword?.message}</span>
                                    </div>
                                    <div className="row textfield-row-main" style={{ marginBottom: "30px", position: "relative" }}>
                                        <div className="col-12 changpswd">
                                            <TextField
                                                id="standard-basic"
                                                label={<RequiredLabel labelText="New Password" />}
                                                variant="standard"
                                                {...register("newpassword")}
                                                type={newpass ? "text" : "password"}
                                            />
                                            {newpass ? <i class="fa-solid fa-eye grey-open" onClick={() => setnewpass(!newpass)}></i> :
                                                <i class="fa-solid fa-eye-slash grey-open" onClick={() => setnewpass(!newpass)}></i>
                                            }
                                        </div>
                                        <span className="errorspan">{errors.newpassword?.message}</span>
                                    </div>
                                    <div className="row textfield-row-main" style={{ marginBottom: "30px", position: "relative" }}>
                                        <div className="col-12 changpswd">
                                            <TextField
                                                id="standard-basic"
                                                label={<RequiredLabel labelText="Confirm Password" />}
                                                variant="standard"
                                                {...register("confirmpassword")}
                                                type={confm ? "text" : "password"}
                                            />
                                            {confm ? <i class="fa-solid fa-eye grey-open" onClick={() => setconfm(!confm)}></i> :
                                                <i class="fa-solid fa-eye-slash grey-open" onClick={() => setconfm(!confm)}></i>
                                            }
                                        </div>
                                        <span className="errorspan">{errors.confirmpassword?.message}</span>
                                    </div>
                                    <div className="row textfield-row-main" style={{ marginBottom: "30px" }}>
                                        <div className="col-12">
                                            <button className="save" style={{ width: "100%", marginTop: "20px" }} type='submit'>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default ChangePassword
