import React from 'react';
import ReactDOM from 'react-dom/client';
import "./assets/css/style.css"
import "./assets/css/responsive.css"
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store"; 
import { PersistGate } from 'redux-persist/integration/react';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


axios.interceptors.request.use(

  (config) => {
    const token = localStorage.getItem('AccessToken');
  
    const requiresAccessToken  = config?.requiresAccessToken;
    const requiresSecretkey = config?.requiresSecretkey
    if(requiresSecretkey){
      config.headers["SecretKey"] = process.env.REACT_APP_API_SECRET_KEY;
    }else{
      if (requiresAccessToken && !token) {
        toast.error("NOT AUTHORIZED")
        window.location.href = "/Sign-in";
      } else if (token) {
        const cleantoken = token?.replace(/"/g, '');
        config.headers.Authorization = `Bearer ${cleantoken}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const requiresAuthentication = error.config.headers.Authorization;
    
    if (error.response?.status === 401 && requiresAuthentication) {
    
      window.location.href = "/Sign-in";
      toast.error("NOT AUTHORIZED");
    }

    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Provider store={store}>
      <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
        <BrowserRouter>
          <App />
          <ToastContainer/>
        </BrowserRouter>
      </PersistGate>
    </Provider>
);
