import React from 'react';
import '../components/confirm/assets/Bookingconfirmed.css';
import '../components/confirm/assets/Bookingresponsive.css';
import Confirm from '../components/confirm/Confirm';
import AppHeader from '../components/Common/Header/AppHeader';
import AppFooter from '../components/Common/AppFooter';

function BookingConfirm() {
    return (
        <div className="listing-service-main">
            <AppHeader />
            <div className="mainP HomePage">
                <div className="list_page_layout">
                    <div className="listing-main">
                        <div className="wrapper">
                            <div className="row mt-5 mb-3">
                                <Confirm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter />
        </div>
    )
}

export default BookingConfirm
