import React from 'react';
import AppHeader from '../components/Common/Header/AppHeader';
import AppFooter from '../components/Common/AppFooter';
import "../components/Summury/Bookingsummary.css";
import "../components/Summury/Responsive.css";
import DetailsSummury from '../components/Summury/DetailsSummury';

function BookingSummury() {
  return (
    <div className="listing-service-main">
            <AppHeader />
            <div className="mainP HomePage">
                <div className="list_page_layout">
                    <div className="listing-main">
                        <div className="wrapper">
                            <div className="row mt-5 mb-3">
                                <DetailsSummury/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter />
        </div>
  )
}

export default BookingSummury
    