// userSlice.js

import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "User",
  initialState: {
    userInfo: null,
    username: null,
    loading: false,
    error: null,
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setusername: (state, action) => {
      state.username = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setEditMember:(state,action)=>{
      state.editMember = action.payload;
    },
    setBookingSummury:(state,action)=>{
      state.bookingSummury = action.payload;
    }
  },
});

export const { setUserInfo, setLoading, setError,setEditMember,setBookingSummury ,setusername} = userSlice.actions;
export default userSlice.reducer;
