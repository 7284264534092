import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useParams } from "react-router-dom";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { fetchAvailabilityData } from '../../store/AvailabilitySearch';
import { setAppoinmentDate, setselectedSlot } from '../../store/FilterSliceList';
import { fetchAvailableSlotByid } from '../../store/AvailableSlot';
import $ from 'jquery';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { addFamilyMember, getBloodgrouplist, relationList } from "../../services/ApiServices";
import moment from 'moment';
import { fetchFamilylist } from "../../store/FamilymemberList";
import { getValue } from "@testing-library/user-event/dist/utils";
import Typography from '@mui/material/Typography';

function DetailCard() {
    const dispatch = useDispatch()
    const providerId = useParams();
    const [relation, setRelation] = useState([]);
    const [bloodGrp, setBloodGrp] = useState([]);
    const [show, setShow] = useState(false)
    const [date1, setDate1] = useState();
    const [date2, setDate2] = useState();
    const [selectSlot, setselectSlot] = useState();
    const [customdate, setcustomdate] = useState()
    const { viewserviceprovider } = useSelector((state) => state.viewDetail);
    const { userInfo, editMember } = useSelector((state) => state.userDetails);
    const { availableSlots, sloteMsg } = useSelector((state) => state.availableSlots);
    const { selectedSlot } = useSelector((state) => state.filterdata_list)
    const { AvailSlot } = useSelector((state) => state.availSlot)
    
    const { appoinmentDate } = useSelector((state) => state.filterdata_list);
    const [showCalendar, setShowCalendar] = useState();
    const currentDate = appoinmentDate ? new Date(appoinmentDate) : new Date();
    const options = { day: 'numeric', month: 'long', year: ('numeric') };
    const options2 = { weekday: 'long' };
    const options3 = { month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate1 = currentDate?.toLocaleDateString('en-US', options);
    const day1 = currentDate?.toLocaleDateString('en-US', options2);
    const nextDay = new Date(currentDate);
    const today = new Date();
    nextDay.setDate(currentDate.getDate() + 1);
    const formattedDate2 = nextDay?.toLocaleDateString('en-US', options);
    const day2 = nextDay?.toLocaleDateString('en-US', options2);
    const newdate = date1;
    const formattedDate3 = newdate?.toLocaleDateString('en-US', options);
    const [loading, setloading] = useState(false)
    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        email: yup.string().required("Email is required"),
        mobile: yup.string().required("Mobile number required"),
        gender: yup.string().required("Gender is required"),
        relation: yup.string().required("Relation is required"),
        dob: yup.string().required("Date of birth is required"),
        blood_group: yup.string().required("Blood group is required"),
        address: yup.string().required("Address is required"),
    })
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data) => {
        setloading(true)
        try {
            const response = await addFamilyMember({data});
            if(response.Status == 1){
                toast.success(response.Message)
                dispatch(fetchFamilylist(userInfo))
               
            }
            else{
                setError('mobile', { type: 'custom', message: response.Message.mobile });
                setloading(false)
            }                  
            
        } catch (error) {
            
            setloading(false)
            // Add logic for handling login failure (e.g., show error message)
        }
    }

    useEffect(() => {
        listRelation()
        listBloodgroup()
        formatDate()
        setDate2()
    }, [])
    const listRelation = async () => {
        try {
            const response = await relationList({ userInfo });
            setRelation(response.Data)
        } catch (error) {
            
        }
    }
    const listBloodgroup = async () => {
        try {
            const response = await getBloodgrouplist();
            setBloodGrp(response.Data)
        } catch (error) {
            
        }
    }


    const handleButtonClick = () => {
        setShowCalendar(!showCalendar);
    };
    const closeDate = () => {
        setShowCalendar(false)
    }
    const getAvailableSlot = async (data) => {
        const currentDate = new Date(data?.date);
        // Extract day, month, and year from the currentDate object
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();

        // Format day, month, and year to have leading zeros if necessary
        day = String(day).padStart(2, '0');
        month = String(month).padStart(2, '0');

        // Create the formatted date string in "dd-mm-yyyy" format
        let formattedDate = `${day}-${month}-${year}`;

        dispatch(fetchAvailabilityData({
            id: data?.id,
            date: formattedDate
        }));
        closeDate()
    }
    function convertTime(inputTime) {
        // Split the input time into start and end times
        const [startTime, endTime] = inputTime.split('-');

        // Convert start and end times to Date objects
        const startDate = new Date(`2000-01-01T${startTime}`);
        const endDate = new Date(`2000-01-01T${endTime}`);

        // Format start and end times to 'h:mm A' format
        const startFormatted = startDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
        const endFormatted = endDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });

        // Output the formatted time range
        return `${startFormatted} - ${endFormatted}`;
    }
    const inputTime = `${AvailSlot?.time_start}-${AvailSlot?.time_end}`;
    const formattedTime = inputTime ? convertTime(inputTime) : '';
    function getDayFromDate(dateString) {
        // Create a new Date object from the provided date string
        const date = new Date(dateString);

        // Get the day of the week (0-6) from the Date object
        const dayOfWeek = date.getDay();

        // Define an array of day names
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        // Return the day name corresponding to the day of the week
        return daysOfWeek[dayOfWeek];
    }

    const dateString = selectedSlot?.date; // Example date string
    const day = dateString ? getDayFromDate(dateString) : '';
    function formatDate(dateString) {
        // Create a new Date object from the provided date string
        const date = new Date(dateString);

        // Get the day and month from the Date object
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' }); // Full month name

        // Concatenate the day and month with appropriate formatting
        const formattedDate = `${day} ${month}`;

        return formattedDate;
    }

    const dateStringdata = selectedSlot?.date;
    const formattedDate = dateStringdata ? formatDate(dateStringdata) : '';
    const openPopup = () => {
        setShow(true)
        getAvailableSlot({
            id: providerId?.id,
            date: selectedSlot?.date
        })
    }
    const handleSelectSlot = ({ slot,exp, available, provider, date }) => {
        var newdate = new Date(date);
      
        
        // Get the day, month, and year
        var day = newdate.getDate();
        var month = newdate.getMonth() + 1; // Months are zero-based, so we add 1
        var year = newdate.getFullYear();
        // Format the date to 'dd-mm-yyyy' format
        var formattedDate = (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;
        if (available == 1 && exp==0) {
            dispatch(setAppoinmentDate(date))
            setselectSlot(slot)
            dispatch(setselectedSlot({ id: slot, date: date }))
            dispatch(fetchAvailableSlotByid({ id: slot, date: formattedDate }))
            var isModalOpen = $(`exampleModal${providerId?.id}`).hasClass('show');
            var button = document.getElementById("edit_btn")
            if (button && isModalOpen) {
                button.click()
            }
            setShow(false)
        }
    }
    const handleDateChange = (selectedDate) => {
        setDate1(selectedDate);
        const formattedcusDate = selectedDate?.toLocaleDateString('en-US', options);
        
        setcustomdate(formattedcusDate)
        getAvailableSlot({
            id: providerId?.id,
            date: selectedDate
        })
        setShowCalendar(false); // Close the calendar when a date is selected
    };
    const handleDateChange2 = (selectedDate) => {
        setDate2(selectedDate);
        
        let dateconvert = moment(selectedDate).format('YYYY-MM-DD')
        setValue("dob", dateconvert)
    };
    const RequiredLabel = ({ labelText }) => (
        <div>
          <Typography component="span">{labelText}</Typography>
          <Typography component="span" style={{ color: 'red', fontSize: "20px" }}>
            *
          </Typography>
        </div>
      );
    return (
        <>
            <div className="booking-detail-main">
                <h2>Booking Details</h2>
                <div className="row">
                    <div className="col-3"> <img src={viewserviceprovider?.service_provider?.service_provider_photo_url}></img></div>
                    <div className="col-6">
                        <h4>{viewserviceprovider?.service_provider?.service_provider_name}</h4>
                        <p style={{ marginBottom: "10px" }}>
                            {viewserviceprovider?.service_provider?.category?.category_name}{viewserviceprovider?.service_provider?.specialty ? '-' : ''}{viewserviceprovider?.service_provider?.specialty?.speciality_name}
                        </p>
                        <div className="row">
                            <div className="col-1">
                                <i className="fa-solid fa-clock" style={{ color: "rgb(170 166 166)", fontSize: "12px" }}></i></div>
                            <div className="col-11">
                                <p style={{ color: "rgb(170 166 166)", fontSize: "14px", marginBottom: "0", paddingBottom: "0", lineHeight: "1.9" }}>{day},{formattedTime}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1">
                                <i className="fa-solid fa-location-dot" style={{ color: "rgb(170 166 166)", fontSize: "12px" }}></i></div>
                            <div className="col-11">
                                <p style={{ color: "rgb(170 166 166)", fontSize: "14px", marginBottom: "0", paddingBottom: "0", lineHeight: "1.9" }}>{viewserviceprovider?.service_provider?.location? viewserviceprovider?.service_provider?.location?.location_name :viewserviceprovider?.service_provider?.location_name} ,
                                {viewserviceprovider?.service_provider?.city? viewserviceprovider?.service_provider?.city?.city_name : viewserviceprovider?.service_provider?.city_name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 align-items">
                        <h4>Booking Charge</h4>
                        <h5>₹{viewserviceprovider?.service_provider?.booking_charge}</h5>
                        <div className="badge-2">{selectedSlot?.date}</div>
                    </div>
                    <div className="booking-badge">
                        <div className="row" style={{ alignItems: "center" }}>
                            <div className="col-10">
                                <h5>Book An Appointment</h5>
                                <p>{selectedSlot?.date}</p>
                                <p>{day},{formattedTime}</p>
                            </div>
                            <div className="col-2">
                                <button className="edit-button" id="edit_btn" data-toggle="modal" data-target={`#exampleModal${providerId?.id}`} onClick={(e) => openPopup()}><i className="fa-solid fa-pen" style={{ marginRight: "5px" }}></i>Edit</button>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ paddingRight: "0" }}>
                        <div className="create-account-form">
                            <div className="create-form-inner">
                                <div className="row">
                                    <div className="col-6" style={{ paddingRight: "0" }}>
                                        <TextField id="standard-basic" label={<RequiredLabel labelText="Full Name" />} variant="standard" name='name' {...register("name")} />
                                        <div className="errorDetails">{errors.name ? errors.name.message : ''}</div>
                                    </div>
                                    <div className="col-6 margin-change">
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label"><RequiredLabel labelText="Gender" /></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard" s
                                                label="Gender"
                                                name="gender"
                                                {...register("gender")}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value='1'>Male</MenuItem>
                                                <MenuItem value='2'>Female</MenuItem>
                                                <MenuItem value='3'>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <div className="errorDetails">{errors.gender ? errors.gender.message : ''}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="create-form-inner">
                                <div className="row">
                                    <div className="col-6" style={{ paddingRight: "0" }}>
                                        <TextField id="standard-basic" label={<RequiredLabel labelText="Mobile Number" />} variant="standard" name='mobile' {...register("mobile")} 
                                         inputProps={{
                                            maxLength: 10
                                        }}/>
                                        <div className="errorDetails">{errors.mobile ? errors.mobile.message : ''}</div>
                                    </div>
                                    <div className="col-6 margin-change">
                                        <TextField id="standard-basic" label={<RequiredLabel labelText="Email" />} variant="standard" name='email' {...register("email")} />
                                        <div className="errorDetails">{errors.email ? errors.email.message : ''}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="create-form-inner">
                                <div className="row">
                                    <div className="col-6 margin-change">
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label"><RequiredLabel labelText="Relation" /></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name="relation"
                                                {...register("relation")}
                                                label="Relation"
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {relation?.map((obj) => (<MenuItem key={obj?.id} value={obj?.id}>{obj.name}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                        <div className="errorDetails">{errors.relation ? errors.relation.message : ''}</div>
                                    </div>
                                    <div className="col-6" style={{ paddingRight: "0" }}>
                                        <div className="field-parent datepicker">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <div className="date-picker-container">
                                                        <DatePicker
                                                            label={<RequiredLabel labelText="Dob" />}
                                                            disableFuture
                                                            value={date2}
                                                            onChange={handleDateChange2}
                                                        />
                                                        <div className="errorDetails">{errors.dob ? errors.dob.message : ''}</div>
                                                    </div>
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="create-form-inner">
                                <div className="row">
                                    <div className="col-6 margin-change">
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label"><RequiredLabel labelText="Blood Group" /></InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard" s
                                                label="Blood group"
                                                name="blood_group"
                                                {...register("blood_group")}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {bloodGrp?.map((obj) => (<MenuItem key={obj?.id} value={obj?.id}>{obj.blood_group_name}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                        <div className="errorDetails">{errors.blood_group ? errors.blood_group.message : ''}</div>
                                    </div>
                                    <div className="col-6" style={{ paddingRight: "0" }}>
                                        <TextField id="standard-basic" label={<RequiredLabel labelText="Address" />} variant="standard" name='address' {...register("address")} />
                                        <div className="errorDetails">{errors.address ? errors.address.message : ''}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="addmemeber">Add Member</button>
                    </form>
                </div>
            </div>
            {show && (
                <div className="modal fade button-main" id={`exampleModal${providerId?.id}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <Tabs>
                                <TabList>
                                    <Tab onClick={() => getAvailableSlot({
                                        id: providerId?.id,
                                        date: formattedDate1
                                    })}>{appoinmentDate ? (new Date(appoinmentDate)?.toLocaleDateString('en-US', options3) == new Date()?.toLocaleDateString('en-US', options3) ? 'Today' : day1) : day1}<p>{formattedDate1}</p></Tab>
                                    <Tab onClick={() => getAvailableSlot({
                                        id: providerId?.id,
                                        date: formattedDate2
                                    })}>{appoinmentDate ? (new Date(appoinmentDate)?.toLocaleDateString('en-US', options3) == new Date()?.toLocaleDateString('en-US', options3) ? 'Tomorrow' : day2) : day2}<p>{formattedDate2}</p></Tab>
                                    <Tab onClick={() => handleButtonClick({
                                        id: providerId?.id,
                                        date: customdate
                                    })}>More date<p>{formattedDate3 ? formattedDate3 : 'See calender'}</p>

                                    </Tab>
                                </TabList>

                                <TabPanel>
                                    {availableSlots ?
                                        <div className="row large-2">
                                            {availableSlots?.map((slot) => {
                                                const date = new Date(`2000-01-01T${slot.time_start}`);
                                                const formattedStart = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

                                                const date2 = new Date(`2000-01-01T${slot.time_end}`);
                                                const formattedEnd = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });


                                                return (
                                                    <>
                                                        {slot?.is_available == 1 ?
                                                            <div className="col-4" data-dismiss={slot.is_expired == 1?"":"modal"} key={slot?.id} onClick={() => handleSelectSlot({ slot: slot?.id,exp:slot?.is_expired ,available: slot?.is_available, provider: providerId?.id, date: formattedDate1 })}>
                                                                <div className={slot.is_expired == 1 ? "availblae-active-checktime" :
                                                                    slot.is_available == 0 ? "availblae-active-disactive" :
                                                                        slot.id === selectSlot ? "availblae" : "availblae-active"} >
                                                                    {formattedStart} - {formattedEnd}<br />
                                                                    {slot.remaining_count} Available
                                                                </div>
                                                            </div> :
                                                            <div className="col-4" key={slot.id}>
                                                                <div className={slot.is_expired == 1 ? "availblae-active-checktime" :
                                                                    slot.is_available == 0 ? "availblae-active-disactive" :
                                                                        slot.id === selectSlot ? "availblae" : "availblae-active"} >
                                                                    {formattedStart} - {formattedEnd}<br />
                                                                    {slot.remaining_count} Available
                                                                </div>
                                                            </div>}
                                                    </>

                                                )
                                            })}
                                        </div> :
                                        <div className="row large-2" >
                                            <div className='col-12' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <p>{sloteMsg}</p>
                                            </div>
                                        </div>
                                    }
                                </TabPanel>
                                <TabPanel>
                                    {availableSlots ?
                                        <div className="row large-2">
                                            {availableSlots?.map((slot) => {
                                                const date = new Date(`2000-01-01T${slot.time_start}`);
                                                const formattedStart = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

                                                const date2 = new Date(`2000-01-01T${slot.time_end}`);
                                                const formattedEnd = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });


                                                return (
                                                    <div className="col-4" key={slot?.id} data-dismiss={slot.is_expired == 1?"":"modal"} onClick={() => handleSelectSlot({slot: slot?.id,exp:slot?.is_expired ,available: slot?.is_available, provider: providerId?.id, date: formattedDate2 })}>

                                                        <div className={slot.is_expired == 1 ? "availblae-active-checktime" :
                                                            slot.is_available == 0 ? "availblae-active-disactive" :
                                                                slot.id === selectSlot ? "availblae" : "availblae-active"} >
                                                            {formattedStart} - {formattedEnd}<br />
                                                            {slot.remaining_count} Available
                                                        </div>
                                                    </div>

                                                )
                                            })}
                                        </div> :
                                        <div className="row large-2" >
                                            <div className='col-12' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <p>{sloteMsg}</p>
                                            </div>
                                        </div>
                                    }
                                </TabPanel>
                                <TabPanel >
                                    {availableSlots ?
                                        <div className="row large-2">
                                            {availableSlots?.map((slot) => {
                                                const date = new Date(`2000-01-01T${slot.time_start}`);
                                                const formattedStart = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                                                const date2 = new Date(`2000-01-01T${slot.time_end}`);
                                                const formattedEnd = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
                                                return (
                                                    <div className="col-4" key={slot?.id}data-dismiss="modal" onClick={() => handleSelectSlot({ slot: slot?.id,exp:slot?.is_expired ,available: slot?.is_available, provider: providerId?.id, date: customdate })}>

                                                        <div className={slot.is_expired == 1 ? "availblae-active-checktime" :
                                                            slot.is_available == 0 ? "availblae-active-disactive" :
                                                                slot.id === selectSlot ? "availblae" : "availblae-active"} >
                                                            {formattedStart} - {formattedEnd}<br />
                                                            {slot.remaining_count} Available
                                                        </div>
                                                    </div>

                                                )
                                            })}
                                        </div> :
                                        <div className="row large-2" >
                                            <div className='col-12' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <p>{sloteMsg}</p>
                                            </div>
                                        </div>
                                    }
                                    {showCalendar && (
                                        <Calendar onChange={handleDateChange} value={date1} minDate={today} />
                                    )}
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>)}
            
        </>
    )
}

export default DetailCard
