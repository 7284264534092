import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

const SingleComment = ({ comment }) => {
  return (
    <div className="comment-block">
      <span className="quote quote-left">
        <FontAwesomeIcon icon={faQuoteLeft} />
      </span>
      <p>
        {comment.image_url && <img src={'https://hexprojects.in/Service-Provider-Customer/public/storage/testimonials/zEcq3NwnMVguTp0P8MOFfdSpgZtaCMoSICM5wyyW.jpg'} alt="Comment" />}
        {comment.description}
      </p>
      <span>{comment.title}</span>
    </div>
  );
};

export default SingleComment;
