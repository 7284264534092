import React from 'react';
import AppFooter from '../components/Common/AppFooter';
import AppHeader from '../components/Common/Header/AppHeader';


const ErrorNull = () => {
  return (
    <div className="list_page_layout">
      <AppHeader />
      <div className="wrapper">
        <div className="privcyPolicy404">
          <h2>404 <br />Page not found</h2>
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default ErrorNull;
