import React from 'react';
import { Route } from 'react-router-dom';
import AppFooter from '../components/Common/AppFooter';
import { Outlet } from 'react-router-dom';
import AppHeader from '../components/Common/Header/AppHeader';

const AuthLayout = () => {
  return (
    <div className="inner_page_layout">
      <AppHeader />
      <div className="layout-sec">
        <div className="wrapper">
          <Outlet />
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default AuthLayout;
