import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const DetailNews = ({ news }) => {
  const navigate=useNavigate();
  const formattedDate = () => {
    // Assuming news.date is a string representation of the date
    const date = news?.date?new Date(news?.date):new Date();

    // Format the date as DD-MM-YYYY
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };
  const newsDetails=()=>{
    navigate('/news-details',{state:{id:news.id}})
  }

  return (
    <div className="box-news" onClick={newsDetails}>
        <img src={news.image_url} alt={news.title} />
        <div className="news_d">
          <h3>{news.title}</h3>
          <div className="auth-d">
            <span>{formattedDate()}</span>
            <span>{news.Author}</span>
          </div>
          <p>{news.description}</p>
        </div>
    </div>
  );
};

export default DetailNews;
