import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AuthTitle from './AuthTitle';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import pswd from "../../assets/images/pswd.png";
import { ForgotPasswordApi } from '../../services/ApiServices';
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const title = 'Forgot Password';
    const [onld, setOnld] = useState('hideOnlod');
    const schema = yup.object().shape({
        customer_mobile: yup.string().required("Mobile number required"),
    })
    const {
        register,
        handleSubmit,
        reset,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data) => {
        try {
            const response = await ForgotPasswordApi(data?.customer_mobile);
         
            if (response.Status === 1) {
                toast.success(response.Message)
                setTimeout(() => {
                    navigate('/verify-reset-otp', { state: { data: response.Data } });
                }, 1500);
            } else if (response.Status === 0) {
                toast.error(response.Message)
            }
            // Add logic for successful login (e.g., redirect or set user state)
        } catch (error) {
            
            // Add logic for handling login failure (e.g., show error message)
        }
    }
    const handleInputChange = (e) => {
        // Allow only numeric input
        const value = e.target.value.replace(/[^0-9]/g, '');
        e.target.value = value;

        // Your additional logic if needed
    };
    const resets = (ev) => {
        if (ev === 'ph') {
            setOnld('');
        }
    };
    const checkP = () => {
        if (this.login.username != '') {
            onld = ''
        }
        else {
            onld = 'hideOnlod'
        }
    }
    return (
        <>
            <div className="signin forgot">
                <div className="authform">
                    <div>
                        <AuthTitle title={title} />
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form">
                                <label>
                                    Mobile Number <b style={{ color: 'red' }}>*</b>
                                    <div className={`prefix signinPrefix ${onld === 'hideOnlod' ? 'hideOnlod' : ''}`}>
                                        <span>+91</span>
                                        <input
                                            onFocus={() => resets('ph')}
                                            onInput={handleInputChange}
                                            autoComplete="new-mobilenumber"
                                            name='customer_mobile'
                                            onBlur={checkP}
                                            maxLength="10"
                                            tu
                                            {...register("customer_mobile")}
                                        />
                                    </div>
                                    <div className="error">{errors.customer_mobile ? errors.customer_mobile.message : ''}</div>
                                </label>
                                <button type='submit'>Send Request</button>
                            </div>
                        </form>
                    </div>
                </div>
                <img src={pswd} className="pos-image-auth" alt="Password" />
            </div>
            
        </>
    );
};

export default ForgotPassword;
