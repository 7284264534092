import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchHomepageDatas,setCurrentplace } from '../store/HomeSlice';
import AdsHome from '../components/Home/AdsHome';
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import HomeBanner from '../components/Home/HomeBanner';
import RecentlyBooked from '../components/Home/RecentlyBooked';
import FeaturedSpeciality from '../components/Home/FeaturedSpeciality';
import BookingStep from '../components/Home/BookingStep';
import TopBooking from '../components/Home/TopBooking';
import BookNowhome from '../components/Home/BookNowhome';
import { fetchNewsDatas } from '../store/NewsSlice';
import NewsandEvents from '../components/Home/NewsandEvents';
import { fetchTestimonialsDatas } from '../store/TestimonialsSlice';
import Testimonials from '../components/Home/Testimonials';
import AppFooter from '../components/Common/AppFooter';
import { fetchContactusDatas } from '../store/ContactusSlice';
import AppHeader from '../components/Common/Header/AppHeader';
import axios  from 'axios';
import { 
  fetchCategoryDatas, 
  fetchSubcategoryDatas, 
  fetchSpecialityDatas, 
  setSelectedCategory, 
  setSelectedSubCategory, 
  setSelectedSpeciality,
  setAppoinmentDate,
  setDistance,
  setSortBy
  } from '../store/FilterSliceList';
import { fetchaccountlist } from '../store/Accountlist';




const HomePage = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('AccessToken');
  const { userInfo } = useSelector((state) => state.userDetails);
  const { top_specailty,top_bookings,recent_bookings,currentPlace } = useSelector((state) => state.Homefilter);
  console.log(top_bookings,"jjj");
  useEffect(() => {
    const fetchData = async () => {

      if (navigator.geolocation) {
        
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            const apiKey = 'AIzaSyBTCvNcsYo-DUA3bbtJJkQ_FHm-0y4eeWQ';
            getAddressFromCoordinatesWithRetry(latitude,longitude,apiKey)
          },
          (error) => {
            console.error('Error getting location:', error.message);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    fetchData();
  }, []);
  function getAddressFromCoordinatesWithRetry(latitude, longitude, apiKey, retries = 3, delay = 1000) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    
    const fetchWithRetry = (retryCount) => {
        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data.results && data.results.length > 0) {
                    const placeName = data.results[0].formatted_address;
    
                    dispatch(setCurrentplace({ latitude, longitude, placeName }))
          
                    // Do something with the address
                } else {
                    console.log("Address not found");
                }
            })
            .catch(error => {
                console.error("Error fetching address:", error);
                if (retryCount > 0) {
                    setTimeout(() => fetchWithRetry(retryCount - 1), delay);
                }
            });
    };

    fetchWithRetry(retries);
}
  useEffect(() => {
    const isoString = new Date().toISOString();
    dispatch(fetchHomepageDatas({
      token:userInfo?.access_token,
      customerId:userInfo?.Data?.id,
      latitude:currentPlace?.latitude,
      longitude:currentPlace?.longitude
    }))
    dispatch(fetchNewsDatas())
    dispatch(fetchTestimonialsDatas())
    dispatch(fetchContactusDatas())
    dispatch(fetchCategoryDatas())
    dispatch(setSelectedCategory())
    dispatch(setSelectedSubCategory())
    dispatch(fetchSubcategoryDatas())
    dispatch(fetchSpecialityDatas())
    dispatch(setSelectedSpeciality())
    dispatch(setAppoinmentDate(isoString))
    dispatch(setDistance())
    dispatch(setSortBy(''))
    if(token){
    dispatch(fetchaccountlist())
    }
  }, []);

  return (
    <div className="mainP HomePage">
      <AppHeader />
      <div className="banner">
        <HomeBanner />
      </div>
      
   
      <div className="rec-bookrd">
        {recent_bookings?.length>0 && (<RecentlyBooked recent_bookings={recent_bookings} />)}
      </div>
      <div className="featured_speciality">
        {top_specailty && (
          <FeaturedSpeciality title1="Specializations" specialities={top_specailty} />
        )}
      </div>
      <div className="ad-sec">
        <svg
          width={530}
          height={709}
          viewBox="0 0 530 709"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M490.083 163.956C644.341 470.311 304.22 347.873 207.576 497.689C121.616 630.941 -94.9492 843.248 -273.966 684.507C-370.904 118.341 -634.038 83.9899 -655.838 -210.498C-675.748 -479.446 -387.709 105.043 -207.651 129.557C-31.8021 153.497 348.106 -118.008 490.083 163.956Z"
            fill="url(#paint0_linear_282_697)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_282_697"
              x1={246.5}
              y1={20}
              x2={-364.5}
              y2={510}
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FF6D43" />
              <stop offset={0.979605} stopColor="#FF4525" stopOpacity={0.42} />
            </linearGradient>
          </defs>
        </svg>
        <div className="wrapper">
          <AdsHome />
          <BookingStep />
        </div>
      </div>
      <div className="top_tab">
        {top_bookings?.length > 0 && (
          <TopBooking title2="Top Bookings" TopBookings={top_bookings} />
        )}
      </div>
      <div className="book_now_section">
        <BookNowhome />
      </div>
      <div className="newsEvents">
        <NewsandEvents />
      </div>
      <div className="bg-color-sec">
        <div className="testimonials">
          <Testimonials />
        </div>
        <AppFooter />
      </div>
    </div>
  );
};

export default HomePage;
