import React from 'react'
import AppHeader from '../components/Common/Header/AppHeader'
import AppFooter from '../components/Common/AppFooter'
import ProfileDetail from '../components/Detail/ProfileDetail'
import '../components/Detail/asset/style.css'

function ViewProfile() {
  return (
    <div className="listing-service-main">
      <AppHeader />
      <div className="mainP HomePage">
        <div className="list_page_layout">
          <div className="listing-main">
            <ProfileDetail />

          </div>

        </div>

      </div>
      <AppFooter />
    </div>
  )
}

export default ViewProfile
