import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContactInfo } from "../services/ApiServices"


export const fetchContactusDatas = createAsyncThunk(
  "filters/fetchContactusDatas",
  async (params) => {
    const data = await ContactInfo();
        return data.Data;
      }
    );
const ContactusDataSlice = createSlice({
  name: "ContactSearch",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactusDatas.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchContactusDatas.fulfilled, (state, action) => {
        state.info=action.payload;
        state.loading = false;
      })
      .addCase(fetchContactusDatas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
 } = ContactusDataSlice.actions;

export default ContactusDataSlice.reducer;