import React, { useState, useEffect } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';

const TopBooking = ({ title2, TopBookings }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className="bookings_top">
      <div className="wrapper">
        <div className="title-sec-s">
          <h2 className="tile-h">{title2}</h2>
        </div>
        <Carousel responsive={responsive}>
        {TopBookings?.map((profile) => (
              <div className="single_profile top-profile" key={profile?.service_provider_id}>
                <div className="profile_img">
                  <Link
                    to={'/view-profile/'+profile.service_provider_id}
                    className="position-route"
                  ></Link>
                  <img src={profile.service_provider_image} alt={profile.service_provider_name} />
                </div>
                <div className="profile_det">
                  <Link
                     to={'/view-profile/'+profile.service_provider_id}
                    className="position-route"
                  ></Link>
                  <h3>{profile.service_provider_name}</h3>
                  <span className="dept">{profile.designation?profile.designation:profile.category}</span>
                  <p className="exp_adrsp">{profile.location_name+','+profile.city_name}</p>
                  <p className="shrt-des">{profile.organisation?profile.organisation:profile.firm_name}</p>
                  <p className="shrt-des">{profile.consultation_fees?'Booking Charge ₹'+profile.consultation_fees+'/Person':''}</p>
                </div>
              </div>
            ))}
        </Carousel>

      </div>
    </div>
  );
};

export default TopBooking;
