import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addFavorite } from "../services/ApiServices";


export const fetchfavList = createAsyncThunk(
  "filters/fetchfavList",
  async ({id,userInfo}) => {
    
    const data = await addFavorite({id,userInfo});
        return data
      }
    );
const FavlistSlice = createSlice({
  name: "Favoritlist",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchfavList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchfavList.fulfilled, (state, action) => {
        state.listfavorites=action.payload.Data.data;
        state.sloteMsg=action.payload.Message;
        state.loading = false;
      })
      .addCase(fetchfavList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
 } = FavlistSlice.actions;

export default FavlistSlice.reducer;