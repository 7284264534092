import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccountDetail } from "../services/ApiServices"


export const fetchaccountlist = createAsyncThunk(
  "filters/fetchaccountlist",
  async () => {
    const data = await getAccountDetail();
        return data
      }
    );
const Accountlistslice = createSlice({
  name: "AccountDetail",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchaccountlist.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchaccountlist.fulfilled, (state, action) => {
        state.getAccountDetail=action.payload.Data;
        state.mesg=action.payload.Message;
        state.loading = false;
      })
      .addCase(fetchaccountlist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
 } = Accountlistslice.actions;

export default Accountlistslice.reducer;