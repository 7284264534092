import React, { useEffect, useState } from "react";
import AppHeader from '../Common/Header/AppHeader';
import AppFooter from '../Common/AppFooter';
import Select from 'react-select';
import ListingCard from '../service/ListingCard';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchserviceProvDetail } from "../../store/ServiceproviderDetail";
import { fetchfirmMemberList } from "../../store/FirmMemberlist";
import { fetchAvailabilityData } from "../../store/AvailabilitySearch";
import ListingHeader from "../service/ListingHeader";
import { fetchSpecialityDatas, setAppoinmentDate, setSelectedSpeciality } from "../../store/FilterSliceList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';

function Viewmembers() {
    const id = useParams();
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { listfirmmember, totalRecords, per_page } = useSelector((state) => state.firmmember);
    const { appoinmentDate, selectedSpeciality, specialityData } = useSelector((state) => state.filterdata_list);
    const { userInfo } = useSelector((state) => state.userDetails);
    const currentDate = new Date(appoinmentDate);
    const options0 = { day: 'numeric', month: 'short' };
    const options = { day: 'numeric', month: 'short', year: "numeric" };
    const formattedDate = currentDate?.toLocaleDateString('en-US', options);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(totalRecords / per_page);
    useEffect(() => {
        searchFirm()
        dispatch(fetchSpecialityDatas({
            category_id: location?.state?.catId,
        }))
    }, [id, userInfo])
    const searchFirm = () => {
        const dateObject = new Date(appoinmentDate);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-IN', options).replace(/\//g, '-');
        dispatch(fetchserviceProvDetail({ id, userInfo }))
        dispatch(fetchfirmMemberList({ id: id?.id, specId: selectedSpeciality?.value,appointment_date:formattedDate,page:currentPage }))
        getAvailableSlot({
            id: id?.id,
            date: formattedDate
        })
    }
    const handlePageChange = (data) => {
        const dateObject = new Date(appoinmentDate);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-IN', options).replace(/\//g, '-');
        setCurrentPage(data)
        dispatch(fetchfirmMemberList({ id: id?.id, specId: selectedSpeciality?.value,appointment_date:formattedDate,page:currentPage }))
    }
    const getAvailableSlot = async (data) => {
        
        const currentDate = new Date(data?.date);
        // Extract day, month, and year from the currentDate object
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1; // Note: January is 0
        let year = currentDate.getFullYear();

        // Format day, month, and year to have leading zeros if necessary
        day = String(day).padStart(2, '0');
        month = String(month).padStart(2, '0');

        // Create the formatted date string in "dd-mm-yyyy" format
        let formattedDate = `${day}-${month}-${year}`;

        dispatch(fetchAvailabilityData({
            id: data?.id,
            date: formattedDate
        }));
    }
    const handleChangeSpeciality = (selectedOption) => {
        dispatch(setSelectedSpeciality(selectedOption))
    };
    return (
        <div className="listing-service-main">
            <AppHeader />
            <div className="mainP HomePage">
                <div className="list_page_layout">
                    <div className="listing-main">
                        <div className="wrapper">
                        <div className="listing-header">
                <div className="row" style={{ alignItems: 'center' }}>
                    <div className="col-6 header-brudcrumb-i-listing">
                        <h6>Showing { listfirmmember?.length} Results</h6>
                    </div>
                                <ListingHeader />
                                </div>
            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-3 pt-3">
                                    <div className="listing-search">
                                        <div className="left-sidebar_listing sticky"
                                            style={{ padding: '10px', paddingTop: 0, textAlign: 'center' }}>
                                            <h2>Modify &amp; Search</h2>
                                        </div>
                                        <form action="">
                                            <div className="form-c">
                                                <div className='col-12'>
                                                    <Select
                                                        options={specialityData}
                                                        value={selectedSpeciality ? selectedSpeciality : null}
                                                        isSearchable
                                                        placeholder="Speciality"
                                                        onChange={handleChangeSpeciality}
                                                    />
                                                </div>
                                                <DatePicker
                                                    showIcon
                                                    selected={appoinmentDate ? new Date(appoinmentDate) : new Date()}
                                                    placeholderText="Appoinment date"
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={(date) => dispatch(setAppoinmentDate(date))}
                                                />
                                                <button
                                                    className="pos-but-loader" type='button' onClick={() => searchFirm()}>Search</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-9 main-detain-div">
                                    <div className="row">
                                        {/* <h2>Members</h2> */}
                                        {listfirmmember?.length > 0 ? (
                                            listfirmmember?.map((provider, index) => (
                                                <ListingCard key={index} data={provider} />
                                            ))
                                        ) : <div className='no_data_msg'>
                                            <p>Sorry, we couldn't find any matching results for your search at the moment.!</p>
                                        </div>}
                                    </div>
                                    {listfirmmember && <div className='row mt-5'>
                                        <ResponsivePagination
                                            current={currentPage}
                                            total={totalPages}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <AppFooter />
            </div>
        </div>
    )
}

export default Viewmembers
