import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import contact from "../assets/images/contact.png";
import AppFooter from '../components/Common/AppFooter';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitContactForm } from '../services/ApiServices';
import AppHeader from '../components/Common/Header/AppHeader';


const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    const { info } = useSelector((state) => state.ContactInfo);
    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        email: yup.string().required("Email is required"),
        subject: yup.string().required("Subject is required"),
        Message: yup.string().required("Message is required"),
    })
    const {
        register,
        handleSubmit,
        reset,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data) => {
        try {
            const response = await SubmitContactForm({
                name: data?.name,
                email: data?.email,
                subject: data?.subject,
                Message: data?.Message
            });
            document.getElementById('name').value='';
            document.getElementById('email').value='';
            document.getElementById('subject').value='';
            document.getElementById('Message').value='';
            if (response.Status === 1) {
                toast.success(response.Message)
            } else if (response.Status === 0) {
                toast.error(response.Message)
            }
        } catch (error) {
            
            // Add logic for handling login failure (e.g., show error message)
        }
    }
    return (
        <>
            <div className="inner_page_layout common_page">
                <AppHeader />
                <div className="layout-pg">
                    <div className="wrapper">
                        <div className="contact_form">
                            <div className="sontact_info">
                                <img src={contact} className="pos-im-co" alt="contact" />
                                <div className="box_x">
                                    <h3>Contact Us</h3>
                                    <div className="cbox_s">
                                        <FontAwesomeIcon icon={faPhone} />
                                        <a href={`tel:${info.mobile}`}>+91{info.mobile}</a>
                                    </div>
                                    <div className="cbox_s">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <a href={`mailto:${info.email}`}>{info.email}</a>
                                    </div>

                                    <div className="social_c">
                                        <a href={info?.facebook_link} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faFacebook} />
                                        </a>
                                        <a href={info?.instagram_link} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faInstagram} />
                                        </a>
                                        <a href={info?.twitter_link} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faTwitter} />
                                        </a>
                                        <a href={`https://wa.me/${info?.whatsapp_number}`} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faWhatsapp} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="cfo">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <label>
                                        Name
                                        <input name='name' id='name'
                                            {...register("name")} />
                                        <div className="error_con">{errors.name ? errors.name.message : ''}</div>
                                    </label>
                                    <label>
                                        Email
                                        <input name='email' id='email'
                                            {...register("email")} />
                                        <div className="error_con">{errors.email ? errors.email.message : ''}</div>
                                    </label>
                                    <label>
                                        Subject 
                                        <input name='subject' id='subject'
                                            {...register("subject")} />
                                        <div className="error_con">{errors.subject ? errors.subject.message : ''}</div>
                                    </label>
                                    <label>
                                        Message
                                        <textarea name='Message' id='Message'
                                            {...register("Message")} />
                                        <div className="error_con">{errors.Message ? errors.Message.message : ''}</div>
                                    </label>
                                    <button type='submit' className="sumbit_c_form">
                                        Send
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <AppFooter />
            </div>
            
        </>
    );
};

export default ContactUs;
