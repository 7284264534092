import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { setUserInfo } from '../../store/userSlice';
import Swal from 'sweetalert2'

const AccountSideBar = ({ page }) => {
    const [showAlert, setShowAlert] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const logout = () => {
        dispatch(setUserInfo())
        setShowAlert(false)
        Swal.fire({
            icon: 'success',
            text: 'Logout success',
            showConfirmButton: false,
            timer: 2000
        })
        document.body.style.overflow = "";
        navigate('/')
    };
    return (
        <div className="col-12 col-lg-3">
            <div className="accout_sidebar left-sidebarr">
                <ul>
                    <li><Link to={'/my-account'} aria-current="page" className={page == "myaccount" ? "router-link-exact-active router-link-active" : ""}><i className="fa-solid fa-house"></i>My Account </Link></li>
                    <li><Link to={'/my-family'} className={page == "myfamily" ? "router-link-exact-active router-link-active" : ""}><i className="fa-solid fa-people-roof"></i>My Family </Link></li>
                    <li><Link to={'/booking-list'} className={page == "bookings" ? "router-link-exact-active router-link-active" : ""}><i className="fa-solid fa-book"></i>Bookings </Link></li>
                    <li><Link to={"/booking-history-list"} className={page == "history" ? "router-link-exact-active router-link-active" : ""}><i className="fa-solid fa-clock-rotate-left"></i>History </Link></li>
                    <li><Link to={"/kyc"} className={page == "kyc" ? "router-link-exact-active router-link-active" : ""}><i className="fa-solid fa-upload"></i>Upload KYC </Link></li>
                    <li><Link to={"/favorites"} className={page == "favorite" ? "router-link-exact-active router-link-active" : ""}><i className="fa-solid fa-heart"></i>Favorites</Link></li>
                    <li><Link to={"/notification"} className={page == "notification" ? "router-link-exact-active router-link-active" : ""}><i className="fa-solid fa-bell"></i>Notifications</Link></li>
                    <li><Link to={"/change-password"} className={page == "password" ? "router-link-exact-active router-link-active" : ""}><i className="fa-solid fa-pen"></i>Change Password</Link></li>
                    <li><Link onClick={() => setShowAlert(true)} className={page == "logout" ? "router-link-exact-active router-link-active" : ""}><i className="fa-solid fa-power-off"></i>Logout</Link></li>
                </ul>
            </div>
            {showAlert && (
                <div className="updateSuccess popup-login">
                    <div>
                        <p>Are you sure you want to logout?</p>
                        <div className="yN">
                            <button onClick={() => setShowAlert(false)}>Cancel</button>
                            <button onClick={logout}>Confirm</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AccountSideBar
