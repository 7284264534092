import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHomePageData } from "../services/ApiServices"


export const fetchHomepageDatas = createAsyncThunk(
  "filters/fetchHomepageDatas",
  async (params) => {
    const data = await getHomePageData(params);
        return data.Data;
      }
    );
const HomepageSlice = createSlice({
  name: "HomeSearch",
  initialState: {
  },
  reducers: {
    setCurrentplace: (state, action) => {
      state.currentPlace = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomepageDatas.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHomepageDatas.fulfilled, (state, action) => {
        state.recent_bookings=action.payload?.recent_bookings;
        state.top_specailty=action.payload?.top_specailty;
        state.top_bookings=action.payload?.top_bookings;
        state.top_category_bookings=action.payload?.top_category_bookings;
        state.advertisements=action.payload?.advertisements;
        state.loading = false;
      })
      .addCase(fetchHomepageDatas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
  setCurrentplace,

 } = HomepageSlice.actions;

export default HomepageSlice.reducer;