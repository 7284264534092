import React, { useEffect } from 'react';
import SingleComment from './SingleComment';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useSelector, useDispatch } from 'react-redux';
import lady from "../../assets/images/lady.png";

const Testimonials = () => {
  const dispatch = useDispatch();
  const {testimonials} = useSelector((state) => state.Testimonialfilter);

  return (
    <div className="main_cm">
      <div className="testi_monial">
        <img src={lady} alt="Lady" />
        <div className="comment-sec">
          <h2>
            Our Customers <b>About Us</b>
          </h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
          </p>

          <div className="comments owl-wrapper">
            {testimonials?.length > 0 && (
              <OwlCarousel
                items={1}
                nav={false}
                dots={false}
                autoplay
                responsive={{ 600: { items: 2, nav: false, dots: true, loop: false, margin: 10 } }}
              >
                {testimonials?.map((comment, index) => (
                  <SingleComment key={index} comment={comment} />
                ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;