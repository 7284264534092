import React from 'react';
// import Carousel from "react-multi-carousel";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "react-multi-carousel/lib/styles.css";
import { useSelector, useDispatch } from 'react-redux';
import { fetchSearchResult } from '../../store/SearchServices';
import { fetchFirmResult } from '../../store/SearchFirm';
import {
    setSelectedCategory,
    setSelectedSubCategory,
    setSelectedSpeciality,
    setDistance,
    setProviderType,
    setSortBy
} from '../../store/FilterSliceList';

function Categorylisting() {
    const dispatch = useDispatch();
    const { category_filter } = useSelector((state) => state.filterdata_list);

    const { currentPlace } = useSelector((state) => state.Homefilter);
    const {
        selectedSubCategory,
        selectedSpeciality,
        searchDistance,
        appoinmentDate,
        providerType,
        sortBy
    } = useSelector((state) => state.filterdata_list);


    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    const search = (data) => {
        const dateObject = new Date(appoinmentDate);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = dateObject.toLocaleDateString('en-IN', options).replace(/\//g, '-');
        dispatch(setSelectedCategory({
            label: data?.category_name,
            value: data?.id
        }))
        dispatch(setSelectedSubCategory())
        dispatch(setSelectedSpeciality())
        dispatch(setDistance(''))
        dispatch(setSortBy(''))
        dispatch(setProviderType(data?.provider_type))
        if (data?.provider_type == 1) {
            dispatch(fetchSearchResult({
                selectedCategory: data?.id,
                selectedSubCategory: selectedSubCategory?.value,
                selectedSpecialty: selectedSpeciality?.value,
                selectedLatitude: currentPlace?.latitude,
                selectedLongitude: currentPlace?.longitude,
                searchDistance: searchDistance,
                appoinment_Date: formattedDate
            }))
        } else {
            dispatch(fetchFirmResult({
                selectedCategory: data?.id,
                selectedLatitude: currentPlace?.latitude,
                selectedLongitude: currentPlace?.longitude,
                searchDistance: searchDistance,
                appoinment_Date: formattedDate,
                sortBy: sortBy
            }))
        }

    }
    return (
        <>
            <div className="category-listing">
                {/* <Carousel responsive={responsive}> */}
                <Slider {...settings}>

                    {category_filter?.map((obj, index) => {
                        return (
                            <div className="category-main" key={obj?.id} onClick={() => search(obj)}>
                                <div className="catogary-inner">
                                    <img src={obj?.category_icon_url} alt="Doctor" />
                                </div>
                                <div className="category-text">
                                    <p>{obj?.category_name}</p>
                                </div>
                            </div>
                        )
                    })}
                </Slider>

                {/* </Carousel> */}
            </div>
        </>
    )
}

export default Categorylisting
