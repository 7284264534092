import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTestimonials } from "../services/ApiServices"


const { REACT_APP_SERVER_URL } = process.env;
export const fetchTestimonialsDatas = createAsyncThunk(
  "filters/fetchTestimonialsDatas",
  async (params) => {
    const data = await getTestimonials();
        return data.Data;
      }
    );
const TestimonialsSlice = createSlice({
  name: "TestimonialsSearch",
  initialState: {
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTestimonialsDatas.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTestimonialsDatas.fulfilled, (state, action) => {
        state.testimonials=action.payload;
        state.loading = false;
      })
      .addCase(fetchTestimonialsDatas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { 
 } = TestimonialsSlice.actions;

export default TestimonialsSlice.reducer;