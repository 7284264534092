import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { searchFirm } from "../services/ApiServices"


export const fetchFirmResult = createAsyncThunk(
  "searchFilters/meetupfetchFirmResult",
  async (params) => {
    const data = await searchFirm(params);
        return data.Data;
      }
    );

const dataFirmSlice = createSlice({
  name: "Search",
  initialState: {
    loader: false,
    error: "",
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFirmResult.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchFirmResult.fulfilled, (state, action) => {
        state.firmList = action.payload.data;
        state.totalRecords = action.payload.total;
        state.firmlength = action.payload.total;
        state.per_page = action.payload.per_page;
        state.loader = false;
      })
      .addCase(fetchFirmResult.rejected, (state, action) => {
        state.loader = false;
        state.error = action.error.message;
      });
  },
});

export const { 
 } = dataFirmSlice.actions;

export default dataFirmSlice.reducer;